import { EZContentObject } from '../interfaces/ez-content-object';

export const getObjectForContentId = async (
  contentId: number,
): Promise<EZContentObject> => {
  const url = `${EZAPI}/api/ibexa/v2/content/objects/${contentId}`;
  return fetch(url, {
    headers: {
      Accept: 'application/vnd.ibexa.api.Content+json',
    },
  })
    .then((res) => res.json())
    .catch((error) => { throw new Error(`Could not get object for content id ${contentId}:\r\n\r\n${error}`); });
};
