import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';

export const formNewsletterUnsubscribeMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...normalizedData.data,
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XFormNewsletterUnsubscribe',
      fileUrl: generateComponentUrl('components', 'x-form-newsletter-unsubscribe'),
    },
  };

  return mappedData;
};
