const fetchUrl = async (urlEndpoint: string): Promise<string> => {
  const { url } = await fetch(urlEndpoint).then((res) => res.json());
  return url;
};

const urlInstancesObject = JSON.parse(PRODUCT_URLS_INSTANCES);
export const retrieveUrlInstanceByKey = (key: string): string => {
  if (urlInstancesObject[key]) {
    return urlInstancesObject[key];
  }

  console.warn('No key provided! Return empty value for url instance.');
  return '';
};

export class ProductsUrlProvider {
  private serviceKey: string;

  constructor(serviceKey: string) {
    this.serviceKey = serviceKey;
  }

  async fetchProductUrl(locale: string, productId: string): Promise<string> {
    const urlInstance = retrieveUrlInstanceByKey(this.serviceKey);
    return fetchUrl(`${urlInstance}/productUrls/${locale}/${productId}`);
  }

  async fetchProductModelUrl(locale: string, productModelId: string): Promise<string> {
    const urlInstance = retrieveUrlInstanceByKey(this.serviceKey);
    return fetchUrl(`${urlInstance}/productModelUrls/${locale}/${productModelId}`);
  }

  async fetchProductCategoryUrl(locale: string, categoryId: string): Promise<string> {
    const urlInstance = retrieveUrlInstanceByKey(this.serviceKey);
    return fetchUrl(`${urlInstance}/productCategoryUrls/${locale}/${categoryId}`);
  }
}
