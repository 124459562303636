import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';

export const twoColumnText: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...deleteProps(normalizedData.data, ['children']),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XTwoColumnText',
      fileUrl: generateComponentUrl('components', 'x-two-column-text'),
    },
  };

  return mappedData;
};
