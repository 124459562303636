export const getScaffoldingClass = (metaData) => {
  let scaffoldingClass = '';
  if (metaData.ignoreInScaffolding || metaData.nestedLevel !== 0) {
    return '';
  }
  if (metaData['is-first']) {
    scaffoldingClass = !metaData.noSpacingBottom && !metaData.successiveSpacingBottom ? 'component-spacing-bottom' : '';
  }
  if (!metaData['is-first']) {
    scaffoldingClass = `${!metaData.noSpacingTop && !metaData.successiveSpacingTop ? 'component-spacing-top' : ''} ${!metaData.noSpacingBottom && !metaData.successiveSpacingBottom ? 'component-spacing-bottom' : ''}`;
  }
  if (metaData.successiveSpacingTop) {
    scaffoldingClass += ' component-successive-spacing-top';
  }
  if (metaData.successiveSpacingBottom) {
    scaffoldingClass += ' component-successive-spacing-bottom';
  }
  if (metaData.fullWidth) {
    scaffoldingClass += ' component-full-width';
  }
  return scaffoldingClass;
};
