import { Page } from '../../interfaces/page';

export const appendFooter = (pageData: Page, targetElement: HTMLElement) => {
    const footer = document.createElement('x-footer');
    footer.dataset.resources = '[{paths: ["components/x-footer/index.js"]}]';
    footer.setAttribute(
        'contact',
        JSON.stringify(pageData.footer.companyInformation),
    );
    footer.setAttribute('nav', JSON.stringify(pageData.footer.basicNavigation));
    footer.setAttribute('services', JSON.stringify(pageData.footer.services));
    footer.setAttribute('social', JSON.stringify(pageData.footer.socialMedia));
    footer.setAttribute('locale', JSON.stringify(pageData.footer.locale));
    footer.setAttribute(
        'metanav',
        JSON.stringify(pageData.footer.metaNavigation),
    );
    targetElement.appendChild(footer);
};
