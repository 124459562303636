import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const articleTileMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...renameGeneralProps(normalizedData.data, { title: 'heading' }),
      ...renameGeneralProps(normalizedData.data, { 'load-button-text': 'load-more-button-text' }),
      ...deleteProps(mapChildren(normalizedData.data, 'items'), ['children']),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XArticleTile',
      fileUrl: generateComponentUrl('components', 'x-article-tile'),
    },
  };
  delete mappedData.data.children;

  return mappedData;
};
