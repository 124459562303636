import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';

export const configuratorMapper: DataMapper = (normalizedData) => ({
  data: {
    ...normalizedData.data,
  },
  metaData: {
    ...normalizedData.metaData,
    componentName: 'XConfigurator',
    fileUrl: generateComponentUrl('components', 'x-configurator'),
  },
});
