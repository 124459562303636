import { EZContentField } from '../interfaces/ez-content-field';
import { FileAsset } from '../interfaces/file-asset';
import { filterContentFieldsForIdentifier } from './filter-content-fields-for-identifier';

export const getDataForFile = (contentFields: EZContentField[]): FileAsset => {
  const heading = filterContentFieldsForIdentifier(contentFields, 'name')
    .fieldValue;
  let fileInfo = {
    fileSize: 0,
    mimeType: '',
    url: '',
  };

  if (
    filterContentFieldsForIdentifier(contentFields, 'file').fieldValue !== null
  ) {
    fileInfo = filterContentFieldsForIdentifier(contentFields, 'file')
      .fieldValue;
  } else if (
    filterContentFieldsForIdentifier(contentFields, 'multilingual_file')
      .fieldValue !== null
  ) {
    fileInfo = filterContentFieldsForIdentifier(
      contentFields,
      'multilingual_file',
    ).fieldValue;
  }

  const fileSize = `${Math.round((fileInfo.fileSize / 1024 ** 2) * 100) / 100} MB`;

  let type = fileInfo.mimeType;
  const splitted = type.split('/');
  type = splitted[splitted.length - 1].toUpperCase();

  const { url } = fileInfo;

  const linkTitle = heading;

  return {
    heading,
    fileSize,
    type,
    linkTitle,
    link: url,
  };
};
