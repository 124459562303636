import { convertRichTextToJSON } from '../../helpers/convert-rich-text-to-json';
import { convertTimestampToDDMMYYYY } from '../../helpers/convert-timestamp-to-ddmmyyyy';
import { getContentTypeId } from '../../helpers/get-content-type-id';
import { EZContentObject } from '../../interfaces/ez-content-object';
import { CMSError } from '../error/cms-error';
import { normalizeEZImageAsset } from './normalize-ez-image-asset';
import { normalizeEZObjectRelation } from './normalize-ez-object-relation';
import { normalizeEZObjectRelationList } from './normalize-ez-object-relation-list';
import { normalizeEZSelection } from './normalize-ez-selection';

export const normalizeEZField = async (
  ezFieldType: string,
  contentObject: EZContentObject,
  fieldName: string,
  fieldIdentifier: string,
  fieldValue: any,
  pageId: number,
  lang: string,
) => {
  switch (ezFieldType) {
    case 'ezselection':
      return normalizeEZSelection(
        getContentTypeId(contentObject),
        fieldIdentifier,
        fieldValue,
      ).catch(() => {
        const err = new CMSError(
          `Could not normalize EZ selection field "${fieldName}"`,
        );
        err.fieldName = fieldName;
        throw err;
      });
    case 'ezobjectrelation':
      return normalizeEZObjectRelation(fieldValue, pageId, lang).catch(
        () => {
          const err = new CMSError(
            `Could not normalize EZ object relation field "${fieldName}"`,
          );
          err.fieldName = fieldName;
          throw err;
        },
      );
    case 'ezobjectrelationlist':
      return normalizeEZObjectRelationList(fieldValue, pageId, lang).catch(
        () => {
          const err = new CMSError(
            `Could not normalize EZ object relation list field "${fieldName}"`,
          );
          err.fieldName = fieldName;
          throw err;
        },
      );
    case 'ezrichtext':
      return convertRichTextToJSON((fieldValue || { xml: '' }), lang).catch(() => {
        const err = new CMSError(
          `Could not normalize EZ rich text field "${fieldName}"`,
        );
        err.fieldName = fieldName;
        throw err;
      });
    case 'ezimageasset':
      return normalizeEZImageAsset(fieldValue).catch(() => {
        const err = new CMSError(
          `Could not normalize EZ image asset field "${fieldName}"`,
        );
        err.fieldName = fieldName;
        throw err;
      });
    case 'ezdate':
      if (fieldValue != null) {
        return convertTimestampToDDMMYYYY(fieldValue.timestamp);
      }
      return '';
    default: return fieldValue;
  }
};
