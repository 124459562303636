import { DataMapper } from '../data-mapper';
import { sliderMapper } from './slider';

export const imageSliderMapper: DataMapper = (normalizedData) => {
  const mappedData = sliderMapper(normalizedData);
  mappedData.data.imageslider = '';

  if (mappedData.data.startingslide !== undefined) {
    mappedData.data.startingslide = mappedData.data.startingslide.toString();
  }

  if (mappedData.data.ratio !== '') {
    if (mappedData.data.ratio === 'square 1:1') {
      mappedData.data.ratio = 'square';
    }

    if (mappedData.data.ratio === 'portrait 3:4') {
      mappedData.data.ratio = 'portrait';
    }
  }

  return mappedData;
};
