import { DataMapperForAttributes } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';

export const infoBlockItemMapper: DataMapperForAttributes = (normalizedData) => {
  const mappedData = {
    ...deleteProps(normalizedData.data, ['children']),
  };

  return mappedData;
};
