import { getContentTypeById } from '../content-types/content-type-repository';
import { filterFieldDefinitionForIdentifier } from '../helpers/filter-field-definition-for-identifier';
import { getContentTypeId } from '../helpers/get-content-type-id';
import { getDataForFile } from '../helpers/get-data-for-file';
import { getFieldsForLanguage } from '../helpers/get-fields-for-language';
import { EZContentField } from '../interfaces/ez-content-field';
import { EZPreNormalizedItem } from '../interfaces/ez-pre-normalized-item';
import { EZRawPageData } from '../interfaces/ez-raw-page-data';
import { CMSError } from './error/cms-error';
import { normalizeEZField } from './normalization/normalize-ez-field';

const preNormalizeSubItem = async (
  location: any,
  content: any,
  lang: string,
  pageContentId: number,
): Promise<EZPreNormalizedItem> => {
  const parentLocationParts = location.ParentLocation._href.split('/');
  const parentLocationId = parseInt(
    parentLocationParts[parentLocationParts.length - 1],
    10,
  );

  const locationId = location.id;

  const contentTypeId = getContentTypeId({ Content: content });
  const contentTypeObject = await getContentTypeById(
    contentTypeId,
  ).catch((error) => {
    throw new Error(
      `Could not get content type object for ${content._id}:\r\n\r\n${error}`,
    );
  });
  const contentType = contentTypeObject.ContentType.identifier;
  const contentFields = getFieldsForLanguage({ Content: content }, lang);

  let contentFieldObject: any = {};
  await Promise.all(
    contentFields.map(async (contentField: EZContentField) => {
      let fieldIdentifier = contentField.fieldDefinitionIdentifier;
      const fieldName = filterFieldDefinitionForIdentifier(
        contentTypeObject.ContentType.FieldDefinitions.FieldDefinition,
        fieldIdentifier,
      ).names.value[0]['#text'];
      let { fieldValue } = contentField;
      const ezFieldType = contentField.fieldTypeIdentifier;

      fieldValue = await normalizeEZField(
        ezFieldType,
        { Content: content },
        fieldName,
        fieldIdentifier,
        fieldValue,
        pageContentId,
        lang,
      );

      fieldIdentifier = fieldIdentifier.replace(/_/g, '-');

      if (typeof fieldValue === 'string') {
        fieldValue = fieldValue.split("'").join('&#39;');
        fieldValue = fieldValue.split('"').join('&#34;');
      }

      let isValidValue = true;

      if (fieldValue === '' || fieldValue === null || (Array.isArray(fieldValue) && !fieldValue.length)) {
        isValidValue = false;
      }

      if (isValidValue) {
        contentFieldObject[fieldIdentifier] = fieldValue;
      }
    }),
  ).catch((error: CMSError) => {
    const contentTypeName = contentTypeObject.ContentType.names.value[0]['#text'];
    error.message = `Could not resolve content fields for ${contentTypeName} (content id: ${
      content._id
    }):\r\n\r\n${error}`;
    error.contentTypeName = contentTypeName;
    error.locationId = locationId;

    throw error;
  });

  if (contentType === 'file' && contentFields.length > 0) {
    contentFieldObject = getDataForFile(contentFields);
  }

  return {
    parentLocationId,
    locationId,
    priority: location.priority,
    depth: location.depth,
    contentType,
    contentFields: contentFieldObject,
    id: content._id,
    isChild: false,
  };
};

export const preNormalizeSubItems = (
  rawData: EZRawPageData,
  lang: string,
  pageContentId: number,
): Array<Promise<EZPreNormalizedItem>> => {
  if (!rawData) {
    return undefined;
  }
  const locations = rawData.locationData.View.Result.searchHits.searchHit;
  const contents = rawData.contentData.View.Result.searchHits.searchHit;

  const normalizedNodes = [];
  locations.forEach((location) => {
    const correspondingContent = contents.filter((content) => content.value.Content._id === location.value.Location.ContentInfo.Content._id)[0];

    let ignoreNode = false;

    // remove content that's not maintained in current language
    if (!correspondingContent
      || correspondingContent.value.Content.CurrentVersion.Version.VersionInfo.languageCodes.indexOf(lang) === -1
    ) {
      ignoreNode = true;
    }
    if (!ignoreNode) {
      normalizedNodes.push(
        preNormalizeSubItem(
          location.value.Location,
          correspondingContent.value.Content,
          lang,
          pageContentId,
        ),
      );
    }
  });

  return normalizedNodes;
};
