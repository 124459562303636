import { DataMapperForAttributes } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const brochureMapper: DataMapperForAttributes = (normalizedData) => {
    const mappedData = {
        ...mapChildren(normalizedData.data, 'download'),
        ...deleteProps(
            renameGeneralProps(normalizedData.data, {
                'image.url': 'image-url',
                'image.altText': 'image-alt',
            }), ['children'],
        ),
    };

    mappedData.id = normalizedData.metaData.id;
    mappedData.download = Array.isArray(mappedData.download) ? mappedData.download[0] : mappedData.download;
    if (normalizedData.data.options.entries.length > 0) {
        mappedData.options = normalizedData.data.options.entries.map((entry) => ({ label: entry.label, value: entry.quantity }));
    } else {
        delete mappedData.options;
    }
    return mappedData;
};
