import { NavigationNode } from '../../../interfaces/navigation-node';
import { filterContentFieldsForIdentifier } from '../helpers/filter-content-fields-for-identifier';
import { getFieldsForLanguage } from '../helpers/get-fields-for-language';
import { getLocationHierarchy } from '../helpers/get-location-hierarchy';
import { getLanguageUrl } from '../urls/url-repository';

interface FilteredNavigationNode {
  heading: string;
  headingAlternative: string;
  url: string;
  parentLocationId: string;
  locationId: string;
  depth: number;
  priority: number;
  hidden: boolean;
  major: boolean;
  children?: FilteredNavigationNode[];
}

export const fetchViewRequest = async (url: string, body: any) => {
  let json;
  try {
    json = await fetch(url, {
      headers: {
        'Content-Type': 'application/vnd.ibexa.api.ViewInput+json;version=1.1',
        Accept: 'application/vnd.ibexa.api.View+json',
      },
      method: 'POST',
      body: JSON.stringify(body),
    }).then((response) => response.json());
  } catch (err) {
    return undefined;
  }
  return json;
};

export const fetchNavigationData = async (locationId: number) => {
  const url = `${EZAPI}/api/ibexa/v2/views`;
  const locationQueryBody = {
    ViewInput: {
      identifier: 'navigation',
      public: false,
      LocationQuery: {
        FacetBuilders: {},
        Filter: {
          ContentTypeIdentifierCriterion: 'page',
          SubtreeCriterion: `/1/2/${locationId}/`,
          VisibilityCriterion: false,
        },
        SortClauses: {
          LocationDepth: 'ascending',
        },
        limit: 100000,
        offset: 0,
      },
    },
  };
  const locationData = await fetchViewRequest(url, locationQueryBody);

  const contentQueryBody = {
    ViewInput: {
      identifier: 'navigation',
      public: false,
      ContentQuery: {
        FacetBuilders: {},
        Filter: {
          ContentTypeIdentifierCriterion: 'page',
          SubtreeCriterion: '/1/2/1363/',
        },
        limit: 100000,
        offset: 0,
      },
    },
  };

  const contentData = await fetchViewRequest(url, contentQueryBody);
  return {
    locationData,
    contentData,
  };
};

const areCorresponding = (content, location) => content.value.Content._id === location.value.Location.ContentInfo.Content._id;
const isAvailableInLang = (content, lang) => content.value.Content.CurrentVersion.Version.VersionInfo.languageCodes.indexOf(lang) !== -1;

export const preNormalizeNavigationNode = async (
  location: any,
  content: any,
  lang: string,
): Promise<FilteredNavigationNode> => {
  if (!location) {
    return undefined;
  }

  const parentLocationParts = location.ParentLocation._href.split('/');
  const parentLocationId = parentLocationParts[parentLocationParts.length - 1];
  const locationId = location.id;

  const contentFields = getFieldsForLanguage({ Content: content }, lang);

  const locationTitle = filterContentFieldsForIdentifier(contentFields, 'title')
    ? filterContentFieldsForIdentifier(contentFields, 'title').fieldValue
    : '';
  const alternativeTitle = filterContentFieldsForIdentifier(contentFields, 'title_alternative')
      ? filterContentFieldsForIdentifier(contentFields, 'title_alternative').fieldValue
      : locationTitle;

  const url = await getLanguageUrl(locationId, lang);
  const major = filterContentFieldsForIdentifier(
    contentFields,
    'is_major_navigation_item',
  )
    ? filterContentFieldsForIdentifier(
        contentFields,
        'is_major_navigation_item',
      ).fieldValue
    : '';

  return {
    heading: locationTitle,
    headingAlternative: alternativeTitle,
    url,
    parentLocationId,
    locationId: `${locationId}`,
    depth: location.depth,
    priority: location.priority,
    hidden: location.hidden,
    major,
  };
};

export const preNormalizeNavigationData = (
  rawNavigationData: any,
  lang: string,
): Promise<FilteredNavigationNode[]> => {
  if (!rawNavigationData) {
    return undefined;
  }
  const locations = rawNavigationData.locationData.View.Result.searchHits.searchHit;
  const contents = rawNavigationData.contentData.View.Result.searchHits.searchHit;
  const maxLocationDepth = locations[0].value.Location.depth + parseInt(NAVIGATION_DEPTH, 10);
  const usedLocations = locations.filter((location) => location.value.Location.depth <= maxLocationDepth);

  const normalizedPromises: Array<Promise<FilteredNavigationNode>> = [];
  usedLocations.forEach((location) => {
    const correspondingContent = contents.filter(
      (content) => areCorresponding(content, location) && isAvailableInLang(content, lang),
    )[0];
    if (correspondingContent) {
      normalizedPromises.push(preNormalizeNavigationNode(
        location.value.Location,
        correspondingContent.value.Content,
        lang,
      ));
    }
  });

  return Promise.all(normalizedPromises).then(
    (nodes: FilteredNavigationNode[]) => nodes.filter(({ heading, hidden }) => heading && !hidden),
  );
};

const addChildrenToNode = (
  parentNode: FilteredNavigationNode,
  nodes: FilteredNavigationNode[],
) => {
  const currentNodeId = parentNode.locationId;
  nodes.forEach((e) => {
    if (e.parentLocationId === currentNodeId) {
      e = addChildrenToNode(e, nodes);
      if (!parentNode.children) {
        parentNode.children = [];
      }
      parentNode.children.push(e);
      parentNode.children.sort((nodeA, nodeB) => (nodeA.priority < nodeB.priority ? -1 : 1));
    }
  });
  return parentNode;
};

export const applyNodeHierarchy = (normalizedNodeData: any) => {
  if (!normalizedNodeData) {
    return undefined;
  }

  return addChildrenToNode(
    normalizedNodeData.find((node) => node.locationId === EZ_ROOT_ID),
    normalizedNodeData,
  );
};

const normalizeNavigationData = (
  data: FilteredNavigationNode[],
  currentPageId: number,
  parentIndex = '0',
) => data.map((navItem, index) => ({
    heading: navItem.heading,
    headingAlternative: navItem.headingAlternative,
    url: navItem.url,
    ...(navItem.children && {
      children: normalizeNavigationData(
        navItem.children,
        currentPageId,
        `${parentIndex !== '0' ? parentIndex : ''}${index + 1}`,
      ),
    }),
    id: `${parentIndex !== '0' ? parentIndex : ''}${index + 1}`,
    isCurrentPage: `${navItem.locationId}` === `${currentPageId}`,
    isMajor: navItem.major,
  }));

export const getNavigationStructure: (
  rootId,
  lang,
  currentPageId,
) => Promise<NavigationNode> = async (
  rootId: number,
  lang: string,
  currentPageId: string,
) => {
  const currentPageLocationId = (await getLocationHierarchy(
    parseInt(currentPageId, 10),
  )).pop();

  const rawNavigationData = await fetchNavigationData(rootId);
  if (!rawNavigationData) {
    return [];
  }
  const preNormalizedNavigationData = await preNormalizeNavigationData(
    rawNavigationData,
    lang,
  );

  const navigationStructureHierarchy = applyNodeHierarchy(
    preNormalizedNavigationData,
  );

  const normalizedNavigationStructure = normalizeNavigationData(
    [navigationStructureHierarchy],
    currentPageLocationId,
  );

  return normalizedNavigationStructure ? normalizedNavigationStructure[0] : [];
};
