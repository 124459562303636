import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';
import { normalizeLinkData } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const teaserTileContainerMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...mapChildren(normalizedData.data, 'tiles'),
      ...normalizeLinkData(normalizedData),
      ...normalizeLinkData(normalizedData, 'additional-link'),
      ...deleteProps(
        renameGeneralProps(normalizedData.data, {
            headline: 'main-tile.headline',
            subline: 'main-tile.subline',
            'img.altText': 'main-tile.imgAlt',
            'img.url': 'main-tile.img',
            'bright-text': 'alternative-text-color',
            'additional-link-text': 'additional-link.text',
        }),
        ['children'],
      ),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XTeaserTile',
      fileUrl: generateComponentUrl('components', 'x-teaser-tile'),
      fullWidth: true,
    },
  };

  mappedData.data['main-tile'].link = mappedData.data.link;
  delete mappedData.data.link;

  return mappedData;
};
