export const convertTimestampToDDMMYYYY = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  const day = date.getDate().toString().length === 1
    ? `0${date.getDate()}`
    : date.getDate();
  const month = (date.getMonth() + 1).toString().length === 1
    ? `0${date.getMonth() + 1}`
    : date.getMonth() + 1;
  return `${day}.${month}.${date.getFullYear()}`;
};
