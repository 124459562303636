import { normalizeLinksOnBrochures } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';

export const formOrderBrochureMapper: DataMapper = (normalizedData) => {
  const brochures = normalizedData.data.children.filter((child) => child.metaData.componentIdentifier === 'brochure');
  const groups = normalizedData.data.children.filter((child) => child.metaData.componentIdentifier === 'brochure_group');
  normalizedData.data.children = brochures;
  const brochureData = mapChildren(normalizedData.data, 'brochures');
  normalizedData.data.children = groups;
  const groupData = mapChildren(normalizedData.data, 'categories');
  const mappedData = {
    data: {
        ...brochureData,
        ...groupData,
        ...deleteProps(normalizedData.data, ['children']),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XFormOrderBrochure',
      fileUrl: generateComponentUrl('components', 'x-form-order-brochure'),
    },
  };

  mappedData.data = normalizeLinksOnBrochures(mappedData.data);
  return mappedData;
};
