import { DataMapperForAttributes } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { normalizeLinkData } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const teaserRowItemMapper: DataMapperForAttributes = (normalizedData) => {
  const mappedData = {
    ...normalizeLinkData(normalizedData),
    ...deleteProps(
      renameGeneralProps(normalizedData.data, {
        title: 'heading',
        'rich-text': 'richText',
        'bullet-icon': 'bulletIcon',
      }),
      ['children'],
    ),
  };

  switch (mappedData.bulletIcon) {
    case 'checkmark':
      mappedData.bulletIcon = 'check';
      break;
    case 'en-dash':
      mappedData.bulletIcon = 'dash';
      break;
    case 'numbered':
      mappedData.bulletIcon = 'number';
      break;
    default:
      break;
  }

  return mappedData;
};
