import { DataMapperForAttributes } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { normalizeLinkData } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const routingTeaserItemMapper: DataMapperForAttributes = (normalizedData) => {
  const mappedData = {
    ...normalizeLinkData(normalizedData),
    ...deleteProps(
      renameGeneralProps(normalizedData.data, {
        title: 'heading',
        content: 'text',
        'image.url': 'media',
        'image.altText': 'mediaAlt',
        'price-tag': 'priceTag',
      }),
      ['children'],
    ),
  };

  return mappedData;
};
