import { getContentId } from '../../helpers/get-content-id';
import { getContentType } from '../../helpers/get-content-type';
import { getDataForFile } from '../../helpers/get-data-for-file';
import { getFieldsForLanguage } from '../../helpers/get-fields-for-language';
import { getLocationHierarchy } from '../../helpers/get-location-hierarchy';
import { getObjectForContentId } from '../../helpers/get-object-for-content-id';
import { getLanguageUrl } from '../../urls/url-repository';
import { FileAsset } from '../../interfaces/file-asset';

export const normalizeEZObjectRelation = async (
  fieldValue: any,
  pageId: number,
  lang: string,
): Promise<FileAsset | string> => {
  if (fieldValue.destinationContentId) {
    const locations = await getLocationHierarchy(
      fieldValue.destinationContentId,
    );
    const reversedLocations = [...locations].reverse();

    let closestPageId: number;

    const contentId = await getContentId(reversedLocations[0]);
    const contentObject = await getObjectForContentId(contentId);
    const contentType = await getContentType(contentObject);

    if (contentType === 'file') {
      return getDataForFile(getFieldsForLanguage(contentObject, lang));
    }

    for (const locationStep of reversedLocations) {
      if (locationStep) {
        const locContentId = await getContentId(locationStep);
        if (locContentId !== 0) {
          const locContentObject = await getObjectForContentId(locContentId);
          const locContentType = await getContentType(locContentObject);

          if (locContentType === 'page') {
            closestPageId = locationStep;
            break;
          }
        }
      }
    }

    if (closestPageId === (await getLocationHierarchy(pageId)).pop()) {
      return `#+jumpmark-${fieldValue.destinationContentId}`;
    }
    return getLanguageUrl(locations.pop(), lang);
  }
  return '';
};
