import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';

export const sliderItemMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...normalizedData.data,
      ...deleteProps(normalizedData.data,
        ['children']),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XSliderSlide',
      fileUrl: generateComponentUrl('components/x-slider', 'x-slider-slide'),
      ignoreInScaffolding: true,
    },
  };

  return mappedData;
};
