import { DataMapperForAttributes } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { normalizeLinkData } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const teaserTileItemMapper: DataMapperForAttributes = (normalizedData) => {
  const mappedData = {
    ...normalizeLinkData(normalizedData),
    ...deleteProps(
        renameGeneralProps(normalizedData.data, {
          'img.altText': 'imgAlt',
          'img.url': 'img',
        }),
        ['children'],
    ),
  };

  return mappedData;
};
