
export enum CMSLanguage {
  GERMAN_GERMANY = 'ger-DE',
  ENGLISH_GB = 'eng-GB',
  DANSK_DANMARK = 'dan-DK',
  SVENSKA_SVERIGE = 'swe-SE',
  DUTCH_NEDERLAND = 'dut-NL',
  FRENCH_FRANCE = 'fre-FR',
  FRENCH_BELGIUM = 'fre-BE',
  FRENCH_SUISSE = 'fre-CH',
  GERMAN_SUISSE = 'ger-CH',
  ITALIAN_SUISSE = 'ita-CH',
  ITALIAN_ITALIA = 'ita-IT',
  DANSK_NORWAY = 'dan-NO',
  GERMAN_AUSTRIA = 'ger-AT',
  DUTCH_BELGIUM = 'dut-BE',
  ENGLISCH_FINLAND = 'eng-FI',
}

export enum AkeneoLanguage {
  GERMAN_GERMANY = 'de_DE',
  ENGLISH_GB = 'en_GB',
  DANSK_DANMARK = 'da_DK',
  SVENSKA_SVERIGE = 'sv_SE',
  DUTCH_NEDERLAND = 'nl_NL',
  FRENCH_FRANCE = 'fr_FR',
  FRENCH_BELGIUM = 'fr_BE',
  FRENCH_SUISSE = 'fr_CH',
  GERMAN_SUISSE = 'de_CH',
  ITALIAN_SUISSE = 'it_CH',
  ITALIAN_ITALIA = 'it_IT',
  DANSK_NORWAY = 'da_NO',
  GERMAN_AUSTRIA = 'de_AT',
  DUTCH_BELGIUM = 'nl_BE',
  ENGLISCH_FINLAND = 'en_FI',
}

export enum BrowserLanguage {
  GERMAN_GERMANY = 'de-de',
  ENGLISH_GB = 'en-gb',
  DANSK_DANMARK = 'da-dk',
  SVENSKA_SVERIGE = 'sv-se',
  DUTCH_NEDERLAND = 'nl-nl',
  FRENCH_FRANCE = 'fr-fr',
  FRENCH_BELGIUM = 'fr-be',
  FRENCH_SUISSE = 'fr-ch',
  GERMAN_SUISSE = 'de-ch',
  ITALIAN_SUISSE = 'it-ch',
  ITALIAN_ITALIA = 'it-it',
  DANSK_NORWAY = 'da-no',
  GERMAN_AUSTRIA = 'de-at',
  DUTCH_BELGIUM = 'nl-be',
  ENGLISCH_FINLAND = 'en-fi',
}

export const CMSLanguageSettings = {
  defaultLanguage: CMSLanguage.GERMAN_GERMANY,

  mappingByLanguage: {
    [CMSLanguage.GERMAN_GERMANY]: {
      browserLanguage: BrowserLanguage.GERMAN_GERMANY,
      akeneoLanguage: AkeneoLanguage.GERMAN_GERMANY,
      hasLogin: true,
      loginUrl: 'https://www.siedle.de/meinsiedle',
    },
    [CMSLanguage.ENGLISH_GB]: {
      browserLanguage: BrowserLanguage.ENGLISH_GB,
      akeneoLanguage: AkeneoLanguage.ENGLISH_GB,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.DANSK_DANMARK]: {
      browserLanguage: BrowserLanguage.DANSK_DANMARK,
      akeneoLanguage: AkeneoLanguage.DANSK_DANMARK,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.SVENSKA_SVERIGE]: {
      browserLanguage: BrowserLanguage.SVENSKA_SVERIGE,
      akeneoLanguage: AkeneoLanguage.SVENSKA_SVERIGE,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.DUTCH_NEDERLAND]: {
      browserLanguage: BrowserLanguage.DUTCH_NEDERLAND,
      akeneoLanguage: AkeneoLanguage.DUTCH_NEDERLAND,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.FRENCH_FRANCE]: {
      browserLanguage: BrowserLanguage.FRENCH_FRANCE,
      akeneoLanguage: AkeneoLanguage.FRENCH_FRANCE,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.FRENCH_BELGIUM]: {
      browserLanguage: BrowserLanguage.FRENCH_BELGIUM,
      akeneoLanguage: AkeneoLanguage.FRENCH_BELGIUM,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.FRENCH_SUISSE]: {
      browserLanguage: BrowserLanguage.FRENCH_SUISSE,
      akeneoLanguage: AkeneoLanguage.FRENCH_SUISSE,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.GERMAN_SUISSE]: {
      browserLanguage: BrowserLanguage.GERMAN_SUISSE,
      akeneoLanguage: AkeneoLanguage.GERMAN_SUISSE,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.ITALIAN_SUISSE]: {
      browserLanguage: BrowserLanguage.ITALIAN_SUISSE,
      akeneoLanguage: AkeneoLanguage.ITALIAN_SUISSE,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.ITALIAN_ITALIA]: {
      browserLanguage: BrowserLanguage.ITALIAN_ITALIA,
      akeneoLanguage: AkeneoLanguage.ITALIAN_ITALIA,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.DANSK_NORWAY]: {
      browserLanguage: BrowserLanguage.DANSK_NORWAY,
      akeneoLanguage: AkeneoLanguage.DANSK_NORWAY,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.GERMAN_AUSTRIA]: {
      browserLanguage: BrowserLanguage.GERMAN_AUSTRIA,
      akeneoLanguage: AkeneoLanguage.GERMAN_AUSTRIA,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.DUTCH_BELGIUM]: {
      browserLanguage: BrowserLanguage.DUTCH_BELGIUM,
      akeneoLanguage: AkeneoLanguage.DUTCH_BELGIUM,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
    [CMSLanguage.ENGLISCH_FINLAND]: {
      browserLanguage: BrowserLanguage.ENGLISCH_FINLAND,
      akeneoLanguage: AkeneoLanguage.ENGLISCH_FINLAND,
      hasLogin: false,
      loginUrl: 'https://www.siedle.com/mysiedle',
    },
  },

  languageHasLoginForEZLanguageCode(eZLanguageCode: string): boolean {
    const languageObject = CMSLanguageSettings.mappingByLanguage[eZLanguageCode];
    return languageObject && languageObject.hasLogin ? languageObject.hasLogin : false;
  },

  getLoginUrlForEZLanguageCode(eZLanguageCode: string): string {
    const languageObject = CMSLanguageSettings.mappingByLanguage[eZLanguageCode];
    return languageObject && languageObject.loginUrl ? languageObject.loginUrl : '#';
  },

  getBrowserLanguageForEZLanguageCode(eZLanguageCode: string): string {
    const languageObject = CMSLanguageSettings.mappingByLanguage[eZLanguageCode];
    return languageObject && languageObject.browserLanguage
      ? languageObject.browserLanguage
      : CMSLanguageSettings.getDefaultBrowserLanguage();
  },

  getDefaultBrowserLanguage: (): string => CMSLanguageSettings
    .mappingByLanguage[CMSLanguageSettings.defaultLanguage].browserLanguage,

  getDefaultAkeneoLanguage: (): string => CMSLanguageSettings
    .mappingByLanguage[CMSLanguageSettings.defaultLanguage].akeneoLanguage,
};
