import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const contentWithFeaturesMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...deleteProps(
        renameGeneralProps(normalizedData.data, {
          bubble_text: 'bubble-text',
          'image.url': 'image-url',
          'image.altText': 'image-alt',
        }),
        ['children'],
      ),
      ...mapChildren(normalizedData.data),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XContentWithFeatures',
      fileUrl: generateComponentUrl('components', 'x-content-with-features'),
    },
  };

  return mappedData;
};
