import { filterContentFieldsForIdentifier } from '../../helpers/filter-content-fields-for-identifier';
import { getContentFieldsForContentId } from '../../helpers/get-content-fields-for-content-id';

export const normalizeEZImageAsset = async (
  fieldValue: any,
): Promise<{ url: string; altText: string }> => {
  if (fieldValue.destinationContentId != null) {
    const contentId = fieldValue.destinationContentId;
    const imageObject = await getContentFieldsForContentId(contentId);

    const image = {
      url: `${EZAPI}${
        filterContentFieldsForIdentifier(imageObject, 'image').fieldValue.uri
        }`,
      altText: fieldValue.alternativeText,
    };

    return image;
  }
  return undefined;
};
