import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';
import { normalizeCustomLinksOnChildren, normalizeLinkData } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const downloadListMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...mapChildren(normalizedData.data, 'items'),
      ...normalizeLinkData(normalizedData),
      ...deleteProps(
        renameGeneralProps(normalizedData.data, {
          headline: 'heading',
        }),
        ['children'],
      ),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XDownloadList',
      fileUrl: generateComponentUrl('components', 'x-download-list'),
    },
  };
  mappedData.data = normalizeCustomLinksOnChildren(mappedData.data);

  return mappedData;
};
