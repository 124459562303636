import { EZContentField } from '../interfaces/ez-content-field';

export const filterFieldsForLanguage = (
  field: EZContentField[],
  lang: string,
): EZContentField[] => {
  const tempObj = {};
  field.forEach((multiLangField) => {
    tempObj[multiLangField.fieldDefinitionIdentifier] = tempObj[multiLangField.fieldDefinitionIdentifier] || [];
    tempObj[multiLangField.fieldDefinitionIdentifier].push(multiLangField);
  });
  let langSpecificFields = [];
  for (const key in tempObj) {
    if ({}.hasOwnProperty.call(tempObj, key)) {
      const obj = tempObj[key];
      const preferredLang = obj.filter((o) => o.languageCode === lang);

      // if there are no fields for current language do nothing
      if (preferredLang.length > 0) {
        langSpecificFields = [...langSpecificFields, ...preferredLang];
      } else {
        langSpecificFields = [...langSpecificFields, ...obj];
      }
    }
  }
  return langSpecificFields;
};
