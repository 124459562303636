import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { mapChildren } from '../helpers/map-children';
import { normalizeLinkData } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const primaryCtaMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...renameGeneralProps(normalizedData.data, {
        title: 'heading',
      }),
      ...normalizeLinkData(normalizedData),
      ...mapChildren(normalizedData.data),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XPrimaryCTA',
      fileUrl: generateComponentUrl('components', 'x-primary-cta'),
      fullWidth: true,
      noSpacingTop: true,
      noSpacingBottom: !normalizedData.data['add-white-space-below'],
    },
  };

  return mappedData;
};
