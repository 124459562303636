import { DataMapperForAttributes } from '../data-mapper';

export const fileReferenceMapper: DataMapperForAttributes = (normalizedData) => {
    if (normalizedData.data.file) {
        const mappedData = normalizedData.data.file;

        if (normalizedData.data.name) {
            mappedData.heading = normalizedData.data.name;
        }
        return mappedData;
    }
    const mappedData = normalizedData.data;
    return mappedData;
};
