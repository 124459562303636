import { createEZAdapter } from '../api-adapter/ez/create-ez-adapter';
import { performDataMapping } from '../data-mapper/perform-data-mapping';
import { NormalizedContent } from '../interfaces/normalized-content';
import { Page } from '../interfaces/page';
import { appendFooter } from './framework/append-footer';
import { appendHeader } from './framework/append-header';
import { appendSEOTags } from './framework/append-seo-tags';
import { renderBackToTop } from './framework/render-back-to-top';
import { renderBackToPage } from './framework/render-back-to-page';
import { renderBreadcrumb } from './framework/render-breadcrumb';
import { createHtmlElementFromString } from './helpers/tag-creation/create-html-element-from-string';
import { generateWebComponentTags } from './helpers/tag-creation/generate-web-component-tags';
import { postRendering } from './helpers/post-rendering';

const handlePageFramework = (page: Page, lang: string): void => {
  document.title = `${page.title} - Siedle`;

  appendSEOTags(page);

  const headerContainer: HTMLElement = document.querySelector('header');
  appendHeader(page, headerContainer, lang);

  const footerContainer: HTMLElement = document.querySelector('footer');
  appendFooter(page, footerContainer);
};

const triggerHashNavigation = () => {
  const { hash } = window.location;
  window.location.hash = '';
  window.location.hash = hash;
};

const retrieveProductCategoryOverviewContext = (page: Page) => {
  const matchingContexts = page.children.filter((c) => c.metaData.componentIdentifier === 'product_category_overview');
  if (matchingContexts.length > 0) {
    return matchingContexts[0];
  }

  return undefined;
};

const renderPageTo = async (container: HTMLElement, page: Page, pageId: number, lang: string): Promise<void> => {
  window.addEventListener('siedlePostRendering', () => postRendering(retrieveProductCategoryOverviewContext(page)));

  let firstElementIsStage = false;
  const [firstElement, ...restElements] = page.children
    .map((child: NormalizedContent, index: number) => {
      if (index === 0 && child.metaData.componentName === 'XStage') {
        firstElementIsStage = true;
      }
      return generateWebComponentTags(child);
    });

  const isProductDetail = page.children.filter((child) => child.metaData.componentName === 'XProductDetail').length > 0;
  const isProductCategoryOverview = page.children.filter((child) => child.metaData.componentName === 'XProductCategoryOverview').length > 0;
  const breadcrumb = await renderBreadcrumb(pageId, firstElementIsStage, lang);
  const showBackButton = isProductDetail || isProductCategoryOverview;
  const topElements = [];
  if (showBackButton) {
    topElements.push(renderBackToPage(page));
  }
  topElements.push(breadcrumb);
  const elements = [
    ...(firstElementIsStage ? [firstElement, ...topElements] : [...topElements, firstElement]),
    ...restElements,
  ];

  const htmlElements = [];
  const rendered = [];

  await elements.forEach((element) => {
    const htmlElement = createHtmlElementFromString(element);
    htmlElements.push(htmlElement);

    htmlElement.addEventListener('ready', () => {
      rendered.push(htmlElement);
      if (rendered.length >= htmlElements.length) {
        triggerHashNavigation();
      }
    });

    container.appendChild(htmlElement);
  });

  container.appendChild(createHtmlElementFromString(renderBackToTop()));

  /* adding speical classes for page level customization */
  if (isProductCategoryOverview) {
    container.classList.add('has-xproductcategoryoverview');
  }
  if (isProductDetail) {
    container.classList.add('has-xproductdetail');
  }
};

export const renderContentsToPage = async (pageId: number, lang: string): Promise<void> => {
  const apiAdapter = createEZAdapter();
  const normalizedPage: Page = await apiAdapter.getPageData(pageId, lang);

  handlePageFramework(normalizedPage, lang);

  const mapData = performDataMapping(normalizedPage);
  normalizedPage.children = mapData;

  const root: HTMLElement = document.getElementById('main');
  await renderPageTo(root, normalizedPage, pageId, lang);
};
