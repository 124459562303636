import { Page } from '../../interfaces/page';
import { CMSAdapter } from '../cms-adapter';
import { initialiseContentTypeRepository } from './content-types/content-type-repository';
import { getFooterData } from './footer/get-footer-data';
import { getPageContent } from './get-page-content';
import { EZPage } from './interfaces/ez-page';
import { getGlobalLabels } from './labels/get-global-labels';
import { getNavigationStructure } from './navigation/get-navigation-structure';

export const createEZAdapter = (): CMSAdapter => ({
  getPageData: async (pageId: number, lang: string): Promise<Page> => {
    await initialiseContentTypeRepository();

    const labelMaps = await getGlobalLabels(EZ_GLOBAL_LABELS_ID, lang);

    const pageContentPromise = getPageContent(pageId, lang, labelMaps);
    const navDataPromise = getNavigationStructure(EZ_ROOT_ID, lang, pageId);

    const page: EZPage = await pageContentPromise;
    const navigationStructure = await navDataPromise;

    const footerPromise = getFooterData(
      EZ_FOOTER_ID,
      lang,
      navigationStructure.children,
    );
    const footer = await footerPromise;

    return {
      ...page,
      labelMaps,
      navigationStructure,
      footer,
    };
  },
});
