import { fetchProduct, fetchProductModel } from './x-product-detail';
import {
  createCategoryImageUrl,
  dataOrEmpty,
  fetchProductCategory,
  fetchProductCategoryBreadcrumbItems,
  ProductRelation,
} from './product-render-utils';
import { Content } from '../../../interfaces/content';
import { ProductsUrlProvider } from './products-url-provider';

interface Link {
  url: string;
  label: string;
}

interface Item {
  img: string;
  imgAlt: string;
  link: Link;
}

const itemsByLinkLabel = (one: Item, other: Item) => {
  const labelOne = one.link.label.toLocaleLowerCase();
  const labelOther = other.link.label.toLocaleLowerCase();

  if (labelOne < labelOther) {
    return -1;
  }

  if (labelOne > labelOther) {
    return 1;
  }

  return 0;
};

const productModelAndProductItems = async (productRelations: ProductRelation[]): Promise<Item[]> => {
  const items = [];
  if (productRelations && productRelations.length > 0) {
    const defaultProductsUrlProvider = new ProductsUrlProvider('default');

    const productModelIdentifiers = productRelations
      .filter((p) => p.isModel === true)
      .map((p) => p.identifier);
    const productRelationsNotMatchingModelParent = productRelations
      .filter((p) => p.isModel === false)
      .filter((p) => !productModelIdentifiers.includes(p.parentIdentifier));
    const productRelationModels = productRelations.filter((p) => p.isModel === true);
    const productModels = await Promise.all(
      productRelationModels.map(async (productRelation) => fetchProductModel(productRelation._links.self.href, defaultProductsUrlProvider)),
    );
    const products = await Promise.all(
      productRelationsNotMatchingModelParent.map(async (productRelation) => fetchProduct(productRelation._links.self.href, defaultProductsUrlProvider)),
    );

    productModels.forEach((productModel) => {
      const label = `${dataOrEmpty(productModel.basicProductData.designator)} ${dataOrEmpty(productModel.basicProductData.description)}`;

      items.push({
        img: createCategoryImageUrl(productModel.basicProductData.identifier),
        imgAlt: label,
        link: {
          url: productModel.url,
          label,
        },
      });
    });

    products.forEach((product) => {
      const label = `${dataOrEmpty(product.designatorColor)} ${dataOrEmpty(product.basicProductData.description)}`;
      items.push({
        img: product.basicProductData.imageUrl,
        imgAlt: label,
        link: {
          url: product.url,
          label,
        },
      });
    });

    return items;
  }
};

export const xProductCategoryOverview = async (context: Content): Promise<void> => {
  const element = document.getElementsByTagName('x-product-category-overview')[0];
  const searchParams: Record<string, string> = window.location.search.slice(1).split('&')
    .reduce((acc, slug) => ({
      ...acc,
      [slug.split('=')[0]]: slug.split('=')[1],
    }), {});

  if (!element || !searchParams.categoryIdentifier || !searchParams.locale) {
    return Promise.resolve();
  }

  const {
    label: itemLabel,
    children,
    productRelations,
  } = await fetchProductCategory(searchParams.categoryIdentifier, searchParams.locale);

  const urlServiceKey = context.data['catalogue-url-service'] || 'default';
  const productsUrlProvider = new ProductsUrlProvider(urlServiceKey);
  const breadcrumbElement = document.getElementsByTagName('x-breadcrumb')[0];
  if (breadcrumbElement) {
    const parents = await fetchProductCategoryBreadcrumbItems(searchParams.locale, searchParams.categoryIdentifier, productsUrlProvider, true);
    const currentCrumbs = breadcrumbElement.getAttribute('breadcrumb');
    breadcrumbElement.setAttribute(
      'breadcrumb',
      JSON.stringify(currentCrumbs ? [...JSON.parse(currentCrumbs), ...parents] : parents),
    );
  }

  const items: Item[] = await Promise.all(
    children.map(async ({ identifier, label }) => {
      const url = await productsUrlProvider.fetchProductCategoryUrl(searchParams.locale, identifier);

      return {
        img: CATEGORY_IMAGE_BASE_URL.replace('{code}', identifier),
        imgAlt: label,
        link: { label, url },
      };
    }),
  );

  items.push(...(await productModelAndProductItems(productRelations)));
  items.sort((one, other) => itemsByLinkLabel(one, other));

  if (items.length !== 0) {
    element.setAttribute('title', itemLabel);
    element.setAttribute('items', JSON.stringify(items));
  }
};
