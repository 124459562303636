export const getNestedValue = (data: any, splitProperty: string[]) => {
  if (data[splitProperty[0]] === undefined) {
    return null;
  }
  const deepData = data[splitProperty[0]];
  splitProperty.shift();
  if (splitProperty.length !== 0) {
    return getNestedValue(deepData, splitProperty);
  }
  return deepData;
};

export const setValueForNestedProperty = (
  data: any,
  splitProp: string[],
  value: any = null,
) => {
  if (splitProp.length > 1) {
    if (data[splitProp[0]] === undefined) {
      data[splitProp[0]] = {};
    }
    data[splitProp[0]] = setValueForNestedProperty(
      data[splitProp[0]],
      splitProp.slice(1),
      value,
    );
  } else if (value !== null) {
    data[splitProp[0]] = value;
  }
  return data;
};

export const getValueOrNestedValue = (data: any, targetProperty: string) => {
  if (targetProperty.indexOf('.') !== -1) {
    const splitProp = targetProperty.split('.');
    return getNestedValue(data, splitProp);
  }

  return data[targetProperty];
};

export const duplicateProperty = (data: any, targetProperty: string, newPropertyName: string) => {
    const value = getValueOrNestedValue(data, targetProperty);

    if (value !== undefined && value !== null) {
      if (newPropertyName.indexOf('.') !== -1) {
        const splitProp = newPropertyName.split('.');
        data = setValueForNestedProperty(data, splitProp, value);
      } else {
        data[newPropertyName] = value;
      }
    }

  return data;
};

export const duplicateProps = (data: any, config: any) => {
  for (const newProperty in config) {
    if ({}.hasOwnProperty.call(config, newProperty)) {
      const targetProperty = config[newProperty];
      data = duplicateProperty(data, targetProperty, newProperty);
    }
  }
  return data;
};
