import { EZNormalizedItem } from '../interfaces/ez-pre-normalized-item';

const addChildrenToNode = (parentNode, nodes): EZNormalizedItem => {
  const currentNodeId = parentNode.locationId;

  nodes.forEach((e) => {
    if (e && e.parentLocationId === currentNodeId) {
      e = addChildrenToNode(e, nodes);
      if (!parentNode.children) {
        parentNode.children = [];
      }
      parentNode.children.push(e);
      parentNode.children.sort((nodeA, nodeB) => (nodeA.priority < nodeB.priority ? -1 : 1));
    }
  });

  return parentNode;
};

const addHierarchyDataToNode = (
  node,
  traversionDepth = -1,
): EZNormalizedItem => {
  node.children.forEach((childNode, index) => {
    const childDepth = traversionDepth + 1;
    childNode.nestedLevel = childDepth;
    childNode.parentIdentifier = node.contentType || 'page';
    if (traversionDepth > 0) {
      childNode.isChild = true;
    }
    if (index === 0) {
      childNode.isFirst = true;
    }
    if (index === node.children.length - 1) {
      childNode.isLast = true;
    }
    if (childNode.children) {
      childNode = addHierarchyDataToNode(childNode, childDepth);
    }
  });
  return node;
};

export const applyNodeHierarchy = (
  normalizedNodeData: EZNormalizedItem[],
  pageLocationId: number,
): EZNormalizedItem => {
  if (!normalizedNodeData) {
    return undefined;
  }
  const hierarchedData = addChildrenToNode(
    { locationId: pageLocationId },
    normalizedNodeData,
  );
  if (hierarchedData.children) {
    const extendedData = addHierarchyDataToNode(hierarchedData);
    return extendedData;
  }
  return hierarchedData;
};
