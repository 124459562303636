import { Content } from '../../../../interfaces/content';
import { CMSError } from './cms-error';

export const createErrorComponent = (error: CMSError): Content => ({
    data: {
      heading: `Error in ${error.contentTypeName}`,
      'rich-text': [
        {
          type: 'element',
          attributes: {},
          tagName: 'p',
          children: [
            {
              type: 'text',
              content: 'The field ',
            },
            {
              type: 'element',
              tagName: 'strong',
              children: [
                {
                  type: 'text',
                  content: `${error.fieldName} `,
                },
              ],
            },
            {
              type: 'text',
              content: 'could not be resolved. Please verify ',
            },
            {
              type: 'element',
              attributes: {
                href: `${EZAPI}/admin/content/location/${error.locationId}`,
                title: '',
                target: '_blank',
              },
              tagName: 'a',
              children: [{ type: 'text', content: 'in the CMS' }],
            },
            { type: 'text', content: '.' },
          ],
        },
      ],
    },
    metaData: {
      componentIdentifier: 'text_media_inline',
      id: 1000000,
      nestedLevel: 0,
      isErrorComponent: true,
    },
});
