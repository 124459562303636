import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';
import { normalizeLinkData } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const routingTeaserMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...mapChildren(normalizedData.data, 'cards'),
      ...normalizeLinkData(normalizedData),
      ...deleteProps(
        renameGeneralProps(normalizedData.data, {
          title: 'heading',
          subline: 'text',
        }),
        ['children'],
      ),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XRoutingTeaser',
      fileUrl: generateComponentUrl('components', 'x-routing-teaser'),
      fullWidth: true,
    },
  };

  return mappedData;
};
