import { Page } from '../../interfaces/page';
import { filterContentFieldsForIdentifier } from '../../api-adapter/ez/helpers/filter-content-fields-for-identifier';
import { getContentFieldsForContentId } from '../../api-adapter/ez/helpers/get-content-fields-for-content-id';

// Get absolute URL from a relative one
const getAbsoluteUrl = (uri: string): string => `${EZAPI}${uri}`;

// Add <meta property='...' content='...'> tag
const addProperty = (head: HTMLElement, name: string, value: string) => {
    const element = document.createElement('meta');
    element.setAttribute('property', name);
    element.setAttribute('content', value);

    head.appendChild(element);
};

// Generate absolute canonical URL from content field or fallback to current URL
const getCanonicalUrl = (pageData: Page): string => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  if (pageData.canonicalUrl) {
    const url = new URL(pageData.canonicalUrl, baseUrl);
    return url.href;
  }

  return `${baseUrl}${window.location.pathname}`;
};

export const appendSEOTags = async (pageData: Page) => {
  const head: HTMLElement = document.querySelector('head');

  if (pageData.metaDescription) {
    const metaDesc = document.createElement('meta');
    metaDesc.setAttribute('name', 'description');
    metaDesc.setAttribute('content', pageData.metaDescription);

    head.appendChild(metaDesc);
  }

  addProperty(head, 'og:title', `${pageData.title} - Siedle`);
  addProperty(head, 'og:site_name', 'Siedle');
  addProperty(head, 'og:type', 'website');

  // The OpenGraph locale is the same as the language of the <html> element
  addProperty(head, 'og:locale', document.documentElement.lang.split('-').join('_'));

  if (pageData.metaDescription) {
    // A one or two sentence description of your object https://ogp.me/
    addProperty(head, 'og:description', pageData.metaDescription);
  }

  addProperty(head, 'og:url', getCanonicalUrl(pageData));

  if (pageData.metaImage.destinationContentId) {
    const imageObject = await getContentFieldsForContentId(pageData.metaImage.destinationContentId);

    addProperty(head, 'og:image', getAbsoluteUrl(filterContentFieldsForIdentifier(imageObject, 'image').fieldValue.uri));
    if (pageData.metaImage.alternativeText) {
      addProperty(head, 'og:image:alt', pageData.metaImage.alternativeText);
    }
  }

  if (pageData.metaKeywords) {
    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', pageData.metaKeywords);

    head.appendChild(metaKeywords);
  }

  if (pageData.canonicalUrl) {
    const canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    canonical.setAttribute('href', pageData.canonicalUrl);

    head.appendChild(canonical);
  }
};
