import {
  duplicateProperty,
  getValueOrNestedValue,
} from './duplicate-props';

const removeValueForDeepProp = (data: any, splittedProp: string[]) => {
  if (splittedProp.length > 1) {
    data[splittedProp[0]] = removeValueForDeepProp(
      data[splittedProp[0]],
      splittedProp.slice(1),
    );
  } else if (data !== undefined) {
    delete data[splittedProp[0]];
  }
  return data;
};

export const renameGeneralProps = (data: any, config: any) => {
  for (const oldProperty in config) {
    if ({}.hasOwnProperty.call(config, oldProperty)) {
      const newProperty = config[oldProperty];
      const value = getValueOrNestedValue(data, oldProperty);

      if (value !== undefined && value !== null) {
        data = duplicateProperty(data, oldProperty, newProperty);

        if (oldProperty.indexOf('.') !== -1 && oldProperty !== newProperty) {
          const splitProperty = oldProperty.split('.');
          data = removeValueForDeepProp(data, splitProperty);
        } else {
          delete data[oldProperty];
        }
      }
    }
  }
  return data;
};
