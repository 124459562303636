import {
  AdditionalLinkGroup, Footer, IconLink, Link, LocaleGroup, MetaLinkGroup,
} from '../../../interfaces/footer';
import { NavigationNode } from '../../../interfaces/navigation-node';
import { convertRichTextToJSON } from '../helpers/convert-rich-text-to-json';
import { filterContentFieldsForIdentifier } from '../helpers/filter-content-fields-for-identifier';
import { filterFieldsForLanguage } from '../helpers/filter-fields-for-language';
import { getContentFieldsForContentId } from '../helpers/get-content-fields-for-content-id';
import { getContentTypeId } from '../helpers/get-content-type-id';
import { getFieldsForLanguage } from '../helpers/get-fields-for-language';
import { getLocationHierarchy } from '../helpers/get-location-hierarchy';
import { getObjectForContentId } from '../helpers/get-object-for-content-id';
import { getLanguageUrl } from '../urls/url-repository';
import { EZContentField } from '../interfaces/ez-content-field';
import { normalizeEZSelection } from '../page/normalization/normalize-ez-selection';

const fetchFooterData = async (contentId: number, lang: string): Promise<EZContentField[]> => {
  const rawData = await getObjectForContentId(contentId);
  return getFieldsForLanguage(rawData, lang);
};

const getAdditionalLinkData = async (
  fieldIdentifier: string,
  footerData: EZContentField[],
  lang: string,
): Promise<IconLink[]> => {
  const itemIds = filterContentFieldsForIdentifier(footerData, fieldIdentifier)
    .fieldValue.destinationContentIds;

  return Promise.all<IconLink>(
    itemIds.map(async (itemId) => {
      const itemContent = await getObjectForContentId(itemId);
      const itemData = itemContent.Content.CurrentVersion.Version.Fields.field;
      const itemDataForLanguage = filterFieldsForLanguage(itemData, lang);

      return {
        heading: filterContentFieldsForIdentifier(itemDataForLanguage, 'link').fieldValue
          .text,
        url: filterContentFieldsForIdentifier(itemDataForLanguage, 'link').fieldValue.link,
        iconType: await normalizeEZSelection(
          getContentTypeId(itemContent),
          'icon_type',
          filterContentFieldsForIdentifier(itemDataForLanguage, 'icon_type').fieldValue[0],
        ),
      };
    }),
  );
};

const getMetaNavData = async (
  footerData: EZContentField[],
  lang: string,
): Promise<Link[]> => {
  const metaNavContentIds = filterContentFieldsForIdentifier(
    footerData,
    'meta_nav_items',
  ).fieldValue.destinationContentIds;
  return Promise.all<IconLink>(
    metaNavContentIds.map(async (contentId) => {
      const locationHierarchy = await getLocationHierarchy(contentId);
      const pageContent = await getContentFieldsForContentId(contentId);
      const pageContentForLanguage = filterFieldsForLanguage(pageContent, lang);
      const pageName = filterContentFieldsForIdentifier(pageContentForLanguage, 'title')
        .fieldValue;
      const pageUrl = await getLanguageUrl(locationHierarchy.pop(), lang);
      return {
        heading: pageName,
        url: pageUrl,
      };
    }),
  );
};

const structureFooterData = async (
  footerData: EZContentField[],
  lang: string,
  navStructure: NavigationNode[],
): Promise<Footer> => {
  const companyInformation = await convertRichTextToJSON(
    filterContentFieldsForIdentifier(footerData, 'company_information').fieldValue,
    lang,
  );

  const basicNavigation = navStructure;

  const serviceLinks = await getAdditionalLinkData(
    'services_items',
    footerData,
    lang,
  );
  const services: AdditionalLinkGroup = {
    heading: filterContentFieldsForIdentifier(footerData, 'services_title')
      .fieldValue,
    items: serviceLinks,
  };

  const socialMediaLinks = await getAdditionalLinkData(
    'social_media_items',
    footerData,
    lang,
  );
  const socialMedia: AdditionalLinkGroup = {
    heading: filterContentFieldsForIdentifier(footerData, 'social_media_title')
      .fieldValue,
    items: socialMediaLinks,
  };

  const countryConfigContentIds = filterContentFieldsForIdentifier(footerData, 'country_switch_dropdown_countries').fieldValue.destinationContentIds;
  const countryConfigsAllLangs: EZContentField[][] = await Promise.all(countryConfigContentIds.map(getContentFieldsForContentId));
  const countryConfigs = countryConfigsAllLangs.map((countryConfigAllLangs) => filterFieldsForLanguage(countryConfigAllLangs, lang));

  const normalizedCountryConfigs = countryConfigs.map((countryConfig) => ({
      value: filterContentFieldsForIdentifier(countryConfig, 'url').fieldValue,
      label: filterContentFieldsForIdentifier(countryConfig, 'name').fieldValue,
    })).sort((countryConfigA, countryConfigB) => countryConfigA.label.localeCompare(countryConfigB.label));

  const locale: LocaleGroup = {
    heading: filterContentFieldsForIdentifier(footerData, 'country_switch_heading').fieldValue,
    placeholder: filterContentFieldsForIdentifier(footerData, 'country_switch_dropdown_placeholder').fieldValue,
    countries: normalizedCountryConfigs,
  };

  const metaNav = await getMetaNavData(footerData, lang);
  const metaNavHeading = `© ${(window as any).time.getFullYear()} ${
    filterContentFieldsForIdentifier(footerData, 'meta_nav_title').fieldValue
    }`;

  const metaNavigation: MetaLinkGroup = {
    heading: metaNavHeading,
    items: metaNav,
  };

  return {
    companyInformation,
    basicNavigation,
    locale,
    services,
    socialMedia,
    metaNavigation,
  };
};

export const getFooterData = async (
  contentId: number,
  lang: string,
  navStructure: NavigationNode[],
): Promise<Footer> => {
  const rawFooterData = await fetchFooterData(contentId, lang);
  const structuredFooterData = await structureFooterData(
    rawFooterData,
    lang,
    navStructure,
  );

  return structuredFooterData;
};
