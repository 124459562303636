import { parse } from 'himalaya';
import { RichTextObject } from '../interfaces/rich-text-object';
import { getLanguageUrl } from '../urls/url-repository';
import { getContentId } from './get-content-id';
import { getContentType } from './get-content-type';
import { getDataForFile } from './get-data-for-file';
import { getObjectForContentId } from './get-object-for-content-id';
import { xsl as xslCore } from '../../../xsl-stylesheets/core';

const beautifyJSON = async (
  data: RichTextObject[],
  lang: string,
): Promise<RichTextObject[]> => Promise.all(
  // add attributes to element object
  data.map(async (child) => {
    const attributeObject: any = {};
    if (child.attributes) {
      await Promise.all(
        child.attributes.map(async (attribute) => {
          let { value } = attribute;

          // if attribute is href to ezlocation, replace with url
          if (attribute.value.indexOf('ezlocation') !== -1) {
            const locationId = parseInt(attribute.value.substring(13), 10);
            const contentId = await getContentId(locationId);
            const linkedContent = await getObjectForContentId(contentId);
            const contentType = await getContentType(linkedContent);
            if (contentType === 'file') {
              value = (await getDataForFile(
                linkedContent.Content.CurrentVersion.Version.Fields.field,
              )).link;
            } else if (contentType.indexOf('page') !== -1) {
              value = await getLanguageUrl(locationId, lang);
            }
          }
          attributeObject[attribute.key] = value;
        }),
      );
    }

    let content = child.content || undefined;

    if (content && child.type === 'text') {
      content = content.split('\'').join('&#39;');
    }

    return {
      type: child.type,
      ...(child.attributes !== undefined && { attributes: attributeObject }),
      ...(child.tagName !== undefined && { tagName: child.tagName }),
      ...(content !== undefined && { content }),
      ...(child.children !== undefined && {
        children: await beautifyJSON(child.children, lang),
      }),
    };
  }),
);

const createXsltProcessor = (): XSLTProcessor => {
  const domParser = new DOMParser();
  const xslCoreDom = domParser.parseFromString(xslCore, 'text/xml');

  // biotope gateway complains about XSLTProcess not available in IE11.
  // This code is only important to work in Chrome, because it's preview code.
  const xsltProcessor = new XSLTProcessor();
  xsltProcessor.importStylesheet(xslCoreDom);
  return xsltProcessor;
};

const richTextXmlToHtml5 = (richTextXml: string): string => {
  const xmlDom = new DOMParser().parseFromString(richTextXml, 'text/xml');
  const htmlDocument = createXsltProcessor().transformToFragment(xmlDom, document);
  const temp = document.createElement('div');
  temp.appendChild(htmlDocument);

  return temp.innerHTML;
};

export const convertRichTextToJSON = async (
  richTextFieldValue: any,
  lang: string,
): Promise<RichTextObject[]> => {
  // Ibexa DXP might not support xhtml5edit in the future.
  // XML parsing will be used as fallback, in case the support will be dropped unknowingly
  const html5: string = richTextXmlToHtml5(richTextFieldValue.xml);
  const data = parse(html5);

  const richTextData: RichTextObject[] = await beautifyJSON(data, lang);
  return richTextData[0].children;
};
