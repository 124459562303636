import { getContentTypeById } from '../../content-types/content-type-repository';

export const normalizeEZSelection = async (
  contentTypeId: string,
  fieldName: string,
  fieldValue: number,
): Promise<string> => {
  const contentTypeInfo = await getContentTypeById(
    contentTypeId,
  );

  const contentFieldInfo = contentTypeInfo.ContentType.FieldDefinitions.FieldDefinition;

  const correctField = contentFieldInfo.find((field) => fieldName === field.identifier && field.fieldSettings.options[fieldValue]);

  if (correctField) {
    return correctField.fieldSettings.options[fieldValue].toLowerCase();
  }
  return '';
};
