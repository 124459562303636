import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';

export const featureTeasersMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...deleteProps(normalizedData.data, ['children']),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XFeatureTeasers',
      fileUrl: generateComponentUrl('components', 'x-feature-teasers'),
    },
  };

  return mappedData;
};
