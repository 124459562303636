import { CMSLanguage } from '../configuration/cms-language-settings';

const publicUrlRepository: Map<string, URLIDObject> = new Map<string, URLIDObject>();

interface RawURLIDObject {
  previewUrl: string;
  url: string;
  location: {
    identifier: number;
    parentIdentifier: number;
  };
  content: {
    identifier: number;
  };
  languageCode: CMSLanguage;
}

interface URLIDObject {
  url: string;
  locationId: number;
  languageCode: string;
}

const keyFrom = (object: number | RawURLIDObject, language?: string) => `${
  typeof object === 'number' ? object : object.location.identifier
}-${
  typeof object === 'number' ? language : object.languageCode
}`;

const toURLIDObject = ({ url, location, languageCode }: RawURLIDObject): URLIDObject => ({
  url,
  locationId: location.identifier,
  languageCode,
});

export const initialiseURLRepository = async (): Promise<void> => {
  const urls: RawURLIDObject[] = await fetch(PAGES_URLS_ENDPOINT)
    .then((result) => result.json())
    .catch(() => {
      console.error('Could not fetch urls');
    });

  if (!urls || !Array.isArray(urls)) {
    console.error('List of urls not correctly fetched');
    return;
  }

  urls.forEach((urlId) => {
    // "url" already takes into account system vs custom url
    publicUrlRepository.set(keyFrom(urlId), toURLIDObject(urlId));
  });
};

const fetchUnknownLocation = async (locationId: number, languageCode: string): Promise<URLIDObject> => {
  const urlIds: RawURLIDObject[] = await fetch(
    `${PAGES_URLS_ENDPOINT}?contentLocationId=${locationId}&languageCode=${languageCode}`,
  ).then((result) => result.json());

  const urlid = urlIds.find((object) => languageCode === object.languageCode);

  return urlid ? toURLIDObject(urlid) : {
    url: '',
    locationId,
    languageCode,
  };
};

export const getUrlObject = async (locationId: number, languageCode: string): Promise<URLIDObject> => {
  const urlIdentifier = keyFrom(locationId, languageCode);
  return publicUrlRepository.has(urlIdentifier)
    ? Promise.resolve(publicUrlRepository.get(urlIdentifier))
    : fetchUnknownLocation(locationId, languageCode);
};

export const getLanguageUrl = async (locationId: number, languageCode: string): Promise<string> => {
  const { url } = await getUrlObject(locationId, languageCode);
  return url;
};
