export const getLocationHierarchy = async (
  contentId: number,
): Promise<number[]> => {
  const url = `${EZAPI}/api/ibexa/v2/content/objects/${contentId}/locations`;
  return fetch(url, {
    headers: {
      Accept: 'application/vnd.ibexa.api.Content+json',
    },
  })
    .then((res) => res.json())
    .then((json) => {
      const loc = json.LocationList.Location[0]._href.split('/').splice(8);
      return loc.map((l) => parseInt(l, 10));
    }).catch((error) => { throw new Error(`Could not fetch location hierarchy for content id ${contentId}:\r\n\r\n${error}`); });
};
