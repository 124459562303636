import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { mapChildren } from '../helpers/map-children';
import { normalizeLinkData } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const genericListItemMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...renameGeneralProps(normalizedData.data, {
        headline: 'heading',
        'image.url': 'image-url',
        'image.altText': 'image-alt',
      }),
      ...normalizeLinkData(normalizedData),
      ...mapChildren(normalizedData.data),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XListItem',
      fileUrl: generateComponentUrl('components/x-list', 'x-list-item'),
    },
  };

  return mappedData;
};
