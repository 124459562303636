import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const WizardMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...deleteProps(
        renameGeneralProps(normalizedData.data, {
          step_config: 'step-config',
        }),
        ['children'],
      ),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XWizard',
      fileUrl: generateComponentUrl('components', 'x-wizard'),
    },
  };

  return mappedData;
};
