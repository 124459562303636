import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';

export const productCategoryOverviewMapper: DataMapper = (normalizedData) => ({
    data: {
      ...normalizedData.data,
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XProductCategoryOverview',
      fileUrl: generateComponentUrl('components', 'x-product-category-overview'),
      dependencies: [generateComponentUrl('components', 'x-product-detail')],
    },
});
