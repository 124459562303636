import { accordionMapper } from './mappers/accordion';
import { accordionItemMapper } from './mappers/accordion-item';
import { articleTileMapper } from './mappers/article-tile';
import { articleTileItemMapper } from './mappers/article-tile-item';
import { brochureMapper } from './mappers/brochure';
import { brochureGroupMapper } from './mappers/brochure-group';
import { cardMapper } from './mappers/card';
import { configuratorMapper } from './mappers/configurator';
import { contentWithFeaturesMapper } from './mappers/content-with-features';
import { darkBackgroundMapper } from './mappers/dark-background';
import { downloadListMapper } from './mappers/download-list';
import { featureTeasersMapper } from './mappers/feature-teasers';
import { fileReferenceMapper } from './mappers/file-reference';
import { formContactMapper } from './mappers/form-contact';
import { formNewsletterMapper } from './mappers/form-newsletter';
import { formNewsletterUnsubscribeMapper } from './mappers/form-newsletter-unsubscribe';
import { formOrderBrochureMapper } from './mappers/form-order-brochure';
import { genericListMapper } from './mappers/generic-list';
import { genericListItemMapper } from './mappers/generic-list-item';
import { headlineMapper } from './mappers/headline';
import { heroBannerMapper } from './mappers/hero-banner';
import { imageSliderMapper } from './mappers/image-slider';
import { imageSliderItemMapper } from './mappers/image-slider-item';
import { inPageNavigationMapper } from './mappers/in-page-navigation';
import { inPageNavigationItemMapper } from './mappers/in-page-navigation-item';
import { infoBlockMapper } from './mappers/info-block';
import { infoBlockItemMapper } from './mappers/info-block-item';
import { leadTextMapper } from './mappers/lead-text';
import { offerActionMapper } from './mappers/offer-action';
import { pageIntroductionMapper } from './mappers/page-introduction';
import { primaryCtaMapper } from './mappers/primary-cta';
import { productCategoryOverviewMapper } from './mappers/product-category-overview';
import { productDetailMapper } from './mappers/product-detail';
import { routingTeaserMapper } from './mappers/routing-teaser';
import { routingTeaserItemMapper } from './mappers/routing-teaser-item';
import { secondaryCtaMapper } from './mappers/secondary-cta';
import { securityFeaturesMapper } from './mappers/security-features';
import { serviceSearchDealerMapper } from './mappers/service-search-dealer';
import { serviceSearchHousingMapper } from './mappers/service-search-housing';
import { serviceSearchPartnerMapper } from './mappers/service-search-partner';
import { serviceSearchSalesMapper } from './mappers/service-search-sales';
import { serviceSearchServiceMapper } from './mappers/service-search-service';
import { sliderMapper } from './mappers/slider';
import { sliderItemMapper } from './mappers/slider-item';
import { stageMapper } from './mappers/stage';
import { tabContainerMapper } from './mappers/tab-container';
import { tabItemMapper } from './mappers/tab-item';
import { teaserRowMapper } from './mappers/teaser-row';
import { teaserRowItemMapper } from './mappers/teaser-row-item';
import { teaserTileContainerMapper } from './mappers/teaser-tile-container';
import { teaserTileItemMapper } from './mappers/teaser-tile-item';
import { textMediaInlineMapper } from './mappers/text-media-inline';
import { twoColumnText } from './mappers/two-column-text';
import { WizardMapper } from './mappers/wizard';

export const contentTypeComponentMap = {
    accordion: accordionMapper,
    accordion_item: accordionItemMapper,
    article_tiles: articleTileMapper,
    article_tiles_item: articleTileItemMapper,
    brochure: brochureMapper,
    brochure_group: brochureGroupMapper,
    configurator: configuratorMapper,
    cta_primary: primaryCtaMapper,
    cta_secondary: secondaryCtaMapper,
    lead_text: leadTextMapper,
    file_reference: fileReferenceMapper,
    form_contact: formContactMapper,
    form_order_brochure: formOrderBrochureMapper,
    form_newsletter: formNewsletterMapper,
    form_newsletter_unsubscribe: formNewsletterUnsubscribeMapper,
    text_media_inline: textMediaInlineMapper,
    image_slider: imageSliderMapper,
    image_slider_item: imageSliderItemMapper,
    in_page_navigation_container: inPageNavigationMapper,
    in_page_navigation_item: inPageNavigationItemMapper,
    list_download: downloadListMapper,
    list_generic: genericListMapper,
    list_generic_item: genericListItemMapper,
    page_introduction: pageIntroductionMapper,
    product_detail: productDetailMapper,
    product_category_overview: productCategoryOverviewMapper,
    service_search_dealer: serviceSearchDealerMapper,
    service_search_housing: serviceSearchHousingMapper,
    service_search_partner: serviceSearchPartnerMapper,
    service_search_sales: serviceSearchSalesMapper,
    service_search_service: serviceSearchServiceMapper,
    slider: sliderMapper,
    slider_item: sliderItemMapper,
    stage: stageMapper,
    tab_container: tabContainerMapper,
    tab_item: tabItemMapper,
    teaser_routing: routingTeaserMapper,
    teaser_routing_item: routingTeaserItemMapper,
    teaser_row: teaserRowMapper,
    teaser_row_item: teaserRowItemMapper,
    teaser_tile_container: teaserTileContainerMapper,
    teaser_tile_item: teaserTileItemMapper,
    feature_teasers: featureTeasersMapper,
    security_features: securityFeaturesMapper,
    offer_action: offerActionMapper,
    info_block: infoBlockMapper,
    info_block_item: infoBlockItemMapper,
    content_with_features: contentWithFeaturesMapper,
    hero_banner: heroBannerMapper,
    headline: headlineMapper,
    card: cardMapper,
    wizard: WizardMapper,
    two_column_text: twoColumnText,
    dark_background: darkBackgroundMapper,
};
