import { contentTypeComponentMap } from '../../data-mapper/content-type-component-map';
import { Content } from '../../interfaces/content';
import { LabelMap } from '../../interfaces/label-map';
import { filterContentFieldsForIdentifier } from './helpers/filter-content-fields-for-identifier';
import { getFieldsForLanguage } from './helpers/get-fields-for-language';
import { getLocationHierarchy } from './helpers/get-location-hierarchy';
import { getObjectForContentId } from './helpers/get-object-for-content-id';
import { EZContentField } from './interfaces/ez-content-field';
import { EZPage } from './interfaces/ez-page';
import { EZNormalizedItem } from './interfaces/ez-pre-normalized-item';
import { EZRawPageData } from './interfaces/ez-raw-page-data';
import { fetchViewRequest } from './navigation/get-navigation-structure';
import { applyNodeHierarchy } from './page/apply-node-hierarchy';
import { CMSError } from './page/error/cms-error';
import { createErrorComponent } from './page/error/create-error-component';
import { preNormalizeSubItems } from './page/pre-normalize-sub-items';

// Get page title, breadcrumb...
const getPageFields = async (
  contentId: number,
  lang: string,
): Promise<EZContentField[]> => {
  const pageContent = await getObjectForContentId(contentId).catch((error) => {
    throw new Error(
      `Page content could not be fetched for content id ${contentId}:\r\n\r\n${error}`,
    );
  });

  return getFieldsForLanguage(pageContent, lang);
};


const createDeepQueryBodyFilter = (contentLocationIds: string[], tree: string) => {
  const locationHasContent = contentLocationIds && contentLocationIds.length > 0;

  const viewFilter: any = {
    ContentTypeIdentifierCriterion: Object.keys(contentTypeComponentMap),
  };
  if (locationHasContent) {
    viewFilter.OR = {
      SubtreeCriterion: contentLocationIds.map((contentLocationId) => (`${tree}${contentLocationId}/`)),
    };
  }
  return viewFilter;
};

// Fetch sub item content for whole page
const fetchSubItems = async (contentId: number): Promise<EZRawPageData> => {
  const locations = await getLocationHierarchy(contentId);
  const url = `${EZAPI}/api/ibexa/v2/views`;
  const tree = `/1/2/${locations.join('/')}/`;
  // get locations of contents (!== pages) that are on first level
  const locationBaseQueryBody = {
    ViewInput: {
      identifier: 'locations',
      public: false,
      LocationQuery: {
        FacetBuilders: {},
        Filter: {
          ContentTypeIdentifierCriterion: Object.keys(contentTypeComponentMap),
          ParentLocationIdCriterion: locations.pop(),
          SubtreeCriterion: tree,
          VisibilityCriterion: false,
        },
        limit: 100000,
        offset: 0,
      },
    },
  };
  const locationBaseData = await fetchViewRequest(url, locationBaseQueryBody).catch(
      (error) => {
        throw new Error(
            `Location base data could not be fetched from ${url}:\r\n\r\n${error}`,
        );
      },
  );
  const contentLocationIds: string[] = locationBaseData.View.Result.searchHits.searchHit.map((location) => location.value.Location.id);

  let locationDeepData;
  let contentDeepData;
  const emptyDeepData = {
    View: {
      '_media-type': 'application/vnd.ibexa.api.View+json',
      _href: '/api/ibexa/v2/views/content',
      identifier: 'content',
      Query: {
        '_media-type': 'application/vnd.ibexa.api.Query+json',
      },
      Result: {
        '_media-type': 'application/vnd.ibexa.api.ViewResult+json',
        _href: '/api/ibexa/v2/views/content/results',
        count: 0,
        time: 0,
        timedOut: false,
        maxScore: null,
        searchHits: {
          searchHit: [],
        },
      },
    },
  };
  locationDeepData = emptyDeepData;
  contentDeepData = emptyDeepData;

  if (contentLocationIds && contentLocationIds.length > 0) {
    // get all locations that are under first level contents
    const deepQueryFilterWithVisibility = createDeepQueryBodyFilter(contentLocationIds, tree);
    deepQueryFilterWithVisibility.VisibilityCriterion = false;

    const locationDeepQueryBody = {
      ViewInput: {
        identifier: 'locations',
        public: false,
        LocationQuery: {
          FacetBuilders: {},
          Filter: deepQueryFilterWithVisibility,
          limit: 100000,
          offset: 0,
        },
      },
    };
    locationDeepData = await fetchViewRequest(url, locationDeepQueryBody).catch(
        (error) => {
          throw new Error(
              `Location deep data could not be fetched from ${url}:\r\n\r\n${error}`,
          );
        },
    );
    // get all contents that are under first level contents
    const contentDeepQueryBody = {
      ViewInput: {
        identifier: 'content',
        public: false,
        ContentQuery: {
          FacetBuilders: {},
          Filter: createDeepQueryBodyFilter(contentLocationIds, tree),
          limit: 100000,
          offset: 0,
        },
      },
    };
    contentDeepData = await fetchViewRequest(url, contentDeepQueryBody).catch(
        (error) => {
          throw new Error(
              `Content data could not be fetched from ${url}:\r\n\r\n${error}`,
          );
        },
    );
  }
  return {
    locationData: locationDeepData,
    contentData: contentDeepData,
  };
};

// Filter out and restructure item data
const constructSubItemObject = (data: EZNormalizedItem[]): Content[] => data.map((component) => ({
    data: {
      ...component.contentFields,
      ...(component.children && {
        children: constructSubItemObject(component.children),
      }),
      ...(component.isFirst && { 'is-first': true }),
      ...(component.isLast && { 'is-last': true }),
      ...(component.isChild && { 'is-child': true }),
    },
    metaData: {
      componentIdentifier: component.contentType,
      id: component.id,
      ...(component.isFirst && { 'is-first': true }),
      ...(component.isLast && { 'is-last': true }),
      ...(component.isChild && { 'is-child': true }),
      nestedLevel: component.nestedLevel,
      'parent-identifier': component.parentIdentifier,
    },
  }));

// Normalize data, resolve link/image URLs/files, apply hierarchy, add further information
const normalizeSubItemData = async (
  rawData: EZRawPageData,
  lang: string,
  pageContentId: number,
  pageLocationId: number,
): Promise<Content[]> => {
  const preNormalizedData = [];
  const errorComponents = [];

  for (const subItem of preNormalizeSubItems(rawData, lang, pageContentId)) {
    await subItem
      .then((item) => preNormalizedData.push(item))
      .catch((error: CMSError) => {
        Promise.reject(new Error(`Could not fetch data for component:\r\n\r\n${error}`));
        errorComponents.push(createErrorComponent(error));
      });
  }

  const hierarchedData = applyNodeHierarchy(preNormalizedData, pageLocationId);

  if (hierarchedData.children) {
    const normalizedData = constructSubItemObject(hierarchedData.children);
    normalizedData.push(...errorComponents);

    return normalizedData;
  }
  return [];
};

const applyScaffoldingRules = (components: Content[]): Content[] => {
  const extendedComponents = JSON.parse(JSON.stringify(components));

  extendedComponents.forEach((component: Content, index: number) => {
    if (index > 0 && component.metaData.componentIdentifier === 'text_media_inline' && components[index - 1].metaData.componentIdentifier === 'text_media_inline') {
      component.metaData.successiveSpacingTop = true;
    }
    if (index < components.length - 1 && component.metaData.componentIdentifier === 'text_media_inline' && components[index + 1].metaData.componentIdentifier === 'text_media_inline') {
      component.metaData.successiveSpacingBottom = true;
    }
  });
  return extendedComponents;
};

const addLabelData = (components: Content[], labelMaps: LabelMap[]): Content[] => {
  const labelledComponents = JSON.parse(JSON.stringify(components));

  labelledComponents.forEach((component: Content) => {
    const labelMap = labelMaps.find((lMap) => lMap.contentType === component.metaData.componentIdentifier);
    if (labelMap) {
      labelMap.labels.forEach((label) => {
        component.data[label.fieldIdentifier] = label.fieldValue;
      });
    }
    if (component.data.children) {
      component.data.children = addLabelData(component.data.children, labelMaps);
    }
  });
  return labelledComponents;
};

// Get page specific data
export const getPageContent = async (
  contentId: number,
  lang: string,
  labelMaps: LabelMap[],
): Promise<EZPage> => {
  const location = await getLocationHierarchy(contentId).catch((error) => {
    throw new Error(
      `Page location could not be fetched for content id ${contentId}:\r\n\r\n${error}`,
    );
  });
  const locationId = location.pop();

  const pageFields = await getPageFields(contentId, lang);

  const rawData = await fetchSubItems(contentId);

  const normalizedData = await normalizeSubItemData(
    rawData,
    lang,
    contentId,
    locationId,
  );

  const labelledData = addLabelData(normalizedData, labelMaps);
  const extendedData = applyScaffoldingRules(labelledData);

  return {
    title: filterContentFieldsForIdentifier(pageFields, 'title').fieldValue,
    titleAlternative: filterContentFieldsForIdentifier(pageFields, 'title_alternative').fieldValue,
    breadcrumb: filterContentFieldsForIdentifier(pageFields, 'breadcrumb')
      .fieldValue,
    metaDescription: filterContentFieldsForIdentifier(
      pageFields,
      'meta_description',
    ).fieldValue,
    metaImage: filterContentFieldsForIdentifier(pageFields, 'meta_image').fieldValue,
    metaKeywords: filterContentFieldsForIdentifier(pageFields, 'meta_keywords')
      .fieldValue,
    canonicalUrl: filterContentFieldsForIdentifier(pageFields, 'canonical_url')
      .fieldValue,
    children: extendedData,
  };
};
