import { mapData } from '../perform-data-mapping';

export const mapChildren = (data: any, propertyToMap = 'children') => {
  if (data.children !== undefined) {
    const obj = {};
    obj[propertyToMap] = data.children.map(mapData);
    return obj;
  }
  return {};
};
