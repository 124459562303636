import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { mapChildren } from '../helpers/map-children';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const accordionItemMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...renameGeneralProps(normalizedData.data, { title: 'heading' }),
      ...mapChildren(normalizedData.data),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XAccordionItem',
      fileUrl: generateComponentUrl('components/x-accordion', 'x-accordion-item'),
      noSpacingTop: true,
      noSpacingBottom: true,
    },
  };

  return mappedData;
};
