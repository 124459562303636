import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';

export const serviceSearchPartnerMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: normalizedData.data,
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XSearchFilterPartner',
      fileUrl: generateComponentUrl('components', 'x-search-filter-partner'),
    },
  };

  return mappedData;
};
