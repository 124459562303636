import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';

export const serviceSearchDealerMapper: DataMapper = (normalizedData) => {
    const mappedData = {
        data: { ...normalizedData.data },
        metaData: {
        ...normalizedData.metaData,
        componentName: 'XSearchFilterDealer',
        fileUrl: generateComponentUrl('components', 'x-search-filter-dealer'),
        },
    };
    return mappedData;
};
