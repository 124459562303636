import { DataMapperForAttributes } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const inPageNavigationItemMapper: DataMapperForAttributes = (normalizedData) => {
  const mappedData = {
    ...renameGeneralProps(normalizedData.data, {
      'navigation-point-title': 'navTitle',
      'related-content': 'linkUrl',
    }),
    ...deleteProps(normalizedData.data, ['children']),
  };

  if (mappedData.navTitle.length >= 30) {
    mappedData.navTitle = `${mappedData.navTitle.substring(0, 30)}...`;
  }
  return mappedData;
};
