import { Content } from '../../../../interfaces/content';
import { mapBooleanToLinkTarget } from '../../../../data-mapper/helpers/map-boolean-to-link-target';

const getLinkValues = (internal: boolean, data: any, propName: string) => {
  if (internal) {
    return {
      url: data[`${propName}-internal`],
      label: data[`${propName}-internal-title`] || data[`${propName}-internal`],
    };
  }

  return {
    url: data[`${propName}-external`].link,
    label: data[`${propName}-external`].text || data[`${propName}-external`].link,
  };
};

export const normalizeLinkData = (normalizedData: Content, newPropName?: string): Content => {
  // DEV-NOTE: [newPropName] only works if the property has the same custom prop name in ezCMS AND biotope
  const linkPropName = newPropName || 'link';

  if (normalizedData.data[`${linkPropName}-internal`] || normalizedData.data[`${linkPropName}-external`]) {
    normalizedData.data[linkPropName] = {
      ...getLinkValues(!!normalizedData.data[`${linkPropName}-internal`], normalizedData.data, linkPropName),
      target: mapBooleanToLinkTarget(normalizedData.data[`${linkPropName}-target-blank`]),
    };

    delete normalizedData.data[`${linkPropName}-internal`];
    delete normalizedData.data[`${linkPropName}-internal-title`];
    delete normalizedData.data[`${linkPropName}-external`];
  }

  delete normalizedData.data[`${linkPropName}-target-blank`];

  return normalizedData;
};

export const normalizeCustomLinksOnChildren = (data: any, customKey?: string): Content => {
  const items = data[customKey || 'items'];

  if (!items) {
    return data;
  }

  items.forEach((item) => {
    item.link = {
      url: item.link || '',
      label: item.linkTitle || '',
      target: mapBooleanToLinkTarget(item.linkTarget) || '',
    };

    delete item.linkTitle;
    delete item.linkTarget;
  });

  return data;
};

export const normalizeLinksOnBrochures = (data: any): Content => {
  const items = data.brochures;

  if (!items) {
    return data;
  }

  items.forEach((item) => {
    if (!item.download) {
      return;
    }

    item.download = {
      heading: item.download.heading || '',
      fileSize: item.download.fileSize || '',
      type: item.download.type || '',
      link: {
        url: item.download.link || '',
        label: item.download.linkTitle || item.download.heading || '',
        target: mapBooleanToLinkTarget(item.download.linkTarget) || '',
      },
    };
  });

  return data;
};
