
import { Content } from '../interfaces/content';
import { NormalizedContent } from '../interfaces/normalized-content';
import { Page } from '../interfaces/page';
import { contentTypeComponentMap } from './content-type-component-map';

export const mapData = (content: Content): NormalizedContent => {
  const componentMapper = contentTypeComponentMap[content.metaData.componentIdentifier];
  return componentMapper(content);
};

export const performDataMapping = (pageData: Page) => pageData.children.map((component) => mapData(component));
