import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';

export const darkBackgroundMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...mapChildren(normalizedData.data),
      ...deleteProps(normalizedData.data, ['children']),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XDarkBackground',
      fileUrl: generateComponentUrl('components', 'x-dark-background'),
    },
  };

  if (mappedData.data.children && mappedData.data.children.length >= 2) {
    const lastId = mappedData.data.children.length - 1;
    switch (mappedData.data.slope) {
      case 'both':
        mappedData.data.children[0].data.slot = 'before';
        mappedData.data.children[lastId].data.slot = 'after';
        for (let i = 1; i < lastId; i += 1) {
          mappedData.data.children[i].data.slot = 'main';
        }
        break;
      case 'top':
        mappedData.data.children[0].data.slot = 'before';
        for (let i = 1; i <= lastId; i += 1) {
          mappedData.data.children[i].data.slot = 'main';
        }
        break;
      case 'bottom':
        mappedData.data.children[mappedData.data.children.length].data.slot = 'after';
        for (let i = 0; i < lastId; i += 1) {
          mappedData.data.children[i].data.slot = 'main';
        }
        break;
      default:
        mappedData.data.children.map((c) => {
          c.data.slot = 'main';
          return c;
        });
        break;
    }
  } else {
    mappedData.data.children[0].data.slot = 'main';
  }

  return mappedData;
};
