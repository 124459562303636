import { DataMapperForAttributes } from '../data-mapper';
import { normalizeLinkData } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const articleTileItemMapper: DataMapperForAttributes = (normalizedData) => {
  const mappedData = {
    ...renameGeneralProps(normalizedData.data, {
      'image.url': 'img',
      'image.altText': 'imgAlt',
    }),
    ...normalizeLinkData(normalizedData),
  };

  return mappedData;
};
