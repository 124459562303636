import { CMSLanguageSettings, AkeneoLanguage } from '../../../api-adapter/ez/configuration/cms-language-settings';
import { calculateVat } from '../vat-calculation';
import {
  dataOrEmpty,
  PriceFormat,
  DataFormat,
  Product,
  ProductModel,
  createProductModelBreadcrumbData,
  createProductDetailsBreadcrumbData,
  createCategoryImageUrl,
} from './product-render-utils';
import { ProductsUrlProvider } from './products-url-provider';

const dataOrEmptySafe = (array?: DataFormat): string => {
  const safeTextLongElement = document.createElement('div');
  safeTextLongElement.innerHTML = dataOrEmpty(array) as string;
  return safeTextLongElement.innerHTML
    .replace(/\n/g, '\\n')
    .replace(/"/g, '\\"');
};

const createElement = (tag: string, content: string): string => `{
  "type": "element",
  "tagName": "${tag}",
  "children": [
    {
      "type": "text",
      "content": "${content}"
    }
  ]
}`;

const fetchLanguageUrl = (locale: string, identifier: string, productsUrlProvider: ProductsUrlProvider, isModel = false) => {
  if (isModel) {
    return productsUrlProvider.fetchProductModelUrl(locale, identifier);
  }

  return productsUrlProvider.fetchProductUrl(locale, identifier);
};

const fetchProductOrModel = async (resolvedEndpointUrl: string, productsUrlProvider: ProductsUrlProvider, isModel = false): Promise<Product> => {
  const {
    identifier,
    parent,
    locale,
    family,
    values: {
      europeanArticlenumber,
      designator,
      designatorColor,
      description,
      textShort,
      textLong,
      technicalData,
      colorMaterial,
      discountGroup,
      price,
      image,
      thumbnail,
      productDatasheet,
    },
    associations,
  } = await fetch(resolvedEndpointUrl).then((data) => data.json());

  const url = await fetchLanguageUrl(locale, identifier, productsUrlProvider, isModel);

  const imageUrl = isModel ? createCategoryImageUrl(identifier) : dataOrEmpty(image) as string;

  let formattedPrice = '';
  if (price) {
    const [netPrice] = (dataOrEmpty(price) as PriceFormat[]);
    const grossPrice: string = calculateVat(netPrice.amount);
    formattedPrice = `${grossPrice} ${netPrice.currency}`;
  }

  return {
    basicProductData: {
      identifier,
      family,
      imageUrl,
      designator,
      description,
      textShort,
      textLong,
      technicalData,
      associations,
    },
    parent,
    designatorColor,
    colorMaterial,
    discountGroup,
    europeanArticlenumber,
    image,
    thumbnail,
    productDatasheet,
    price: formattedPrice,
    url,
  };
};

const resolveProductDetailUrl = (locale: string, fetchIdentifier: string, isModel = false) => {
  if (isModel) {
    return PRODUCT_MODELS_ENDPOINT.replace('{locale}', locale).replace('{id}', fetchIdentifier);
  }

  return PRODUCTS_ENDPOINT.replace('{locale}', locale).replace('{id}', fetchIdentifier);
};

export const fetchProduct = async (resolvedEndpoint: string, productsUrlProvider: ProductsUrlProvider): Promise<Product> => fetchProductOrModel(resolvedEndpoint, productsUrlProvider);

export const fetchProductModel = async (resolvedEndpoint: string, productsUrlProvider: ProductsUrlProvider): Promise<ProductModel> => {
  const resultAsProduct = await fetchProductOrModel(resolvedEndpoint, productsUrlProvider, true);
  return {
    basicProductData: resultAsProduct.basicProductData,
    url: resultAsProduct.url,
  };
};

const getOrCreateElement = (tag: string, attr: string, value: string) => {
  let element = document.querySelector(`${tag}[${attr}="${value}"]`);
  if (!element) {
    element = document.createElement(tag);
    element.setAttribute(attr, value);
    document.head.appendChild(element);
  }
  return element;
};

const createProductJSON = (product: Product, forModel = true) => `{
    ${forModel && product.url !== undefined ? `
    "external-link": "${product.url}",
    "file-type": "${product.url.split('.').pop()}",
    ` : ''}
    "identifier": "${product.basicProductData.identifier}",
    "designator": "${dataOrEmpty(product.designatorColor)}",
    "description": "${dataOrEmpty(product.basicProductData.description)}",
    "colorMaterial": "${dataOrEmpty(product.colorMaterial)}",
    "discountGroup": "${dataOrEmpty(product.discountGroup)}",
    "price": "${product.price}"
  }`;

export const xProductDetail = async (): Promise<void> => {
  const element = document.getElementsByTagName('x-product-detail')[0];
  const searchParams: Record<string, string> = window.location.search.slice(1).split('&')
    .reduce((acc, slug) => ({
      ...acc,
      [slug.split('=')[0]]: slug.split('=')[1],
    }), {});

  const isModel = !!searchParams.productModelIdentifier;
  const fetchIdentifier = isModel ? searchParams.productModelIdentifier : searchParams.manufacturerArticlenumber;

  if (!element || !fetchIdentifier || !searchParams.locale) {
    return Promise.resolve();
  }

  const productsUrlProvider = new ProductsUrlProvider('default');
  const productDetailUrl = resolveProductDetailUrl(searchParams.locale, fetchIdentifier, isModel);
  const productDetail = await fetchProductOrModel(productDetailUrl, productsUrlProvider, isModel);

  const mappedAccessories = await Promise.all(
    productDetail.basicProductData.associations.accessory.products
      .map((id) => fetchProduct(resolveProductDetailUrl(searchParams.locale, id), productsUrlProvider)),
  );

  const mappedSpareParts = await Promise.all(
    productDetail.basicProductData.associations.sparePart.products
      .map((id) => fetchProduct(resolveProductDetailUrl(searchParams.locale, id), productsUrlProvider)),
  );

  const mappedArticles = await (isModel ? Promise.all(
    productDetail.basicProductData.associations.article.products
      .map((id) => fetchProduct(resolveProductDetailUrl(searchParams.locale, id), productsUrlProvider)),
    ) : Promise.resolve([productDetail]));

  const product = isModel ? `${dataOrEmpty(productDetail.basicProductData.designator)}` : `${dataOrEmpty(productDetail.designatorColor)}`;
  const pageTitle = `${dataOrEmpty(productDetail.basicProductData.description)} - ${product} - ${document.title}`;
  const productTitle = `${dataOrEmpty(productDetail.basicProductData.description)}\n${product}`;

  const cmsLanguage = Object.keys(CMSLanguageSettings.mappingByLanguage).find(
    (lang) => CMSLanguageSettings.mappingByLanguage[lang].akeneoLanguage === searchParams.locale,
  ) || CMSLanguageSettings.defaultLanguage;
  const otherLanguages = Object.keys(CMSLanguageSettings.mappingByLanguage).filter((lang) => lang !== cmsLanguage);

  // Add protocol and host to a URL
  const getAbsoluteUrl = (path: string): string => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;

    const url = new URL(path, baseUrl);
    return url.href;
  };

  // set page title
  document.title = pageTitle;

  // set OpenGraph title property
  const ogTitle = getOrCreateElement('meta', 'property', 'og:title');
  ogTitle.setAttribute('content', pageTitle);

  // set canonical link
  const url = getAbsoluteUrl(await fetchLanguageUrl(searchParams.locale, fetchIdentifier, productsUrlProvider, isModel));
  const canonical = getOrCreateElement('link', 'rel', 'canonical');
  canonical.setAttribute('href', url);

  // set OpenGraph URL property
  const ogUrlProperty = getOrCreateElement('meta', 'property', 'og:url');
  ogUrlProperty.setAttribute('content', url);

  // set alternate links
  await Promise.all(otherLanguages.map(async (altLanguage) => {
    const settings = CMSLanguageSettings.mappingByLanguage[altLanguage] || {};
    const alternateUrl = getAbsoluteUrl(await fetchLanguageUrl(settings.akeneoLanguage, fetchIdentifier, productsUrlProvider, isModel));
    const altElement = document.createElement('link');
    altElement.setAttribute('rel', 'alternate');
    altElement.setAttribute('href', alternateUrl);
    altElement.setAttribute(
      'hreflang',
      altLanguage === CMSLanguageSettings.defaultLanguage
        ? 'x-default'
        : CMSLanguageSettings.mappingByLanguage[altLanguage].browserLanguage,
    );
    document.head.appendChild(altElement);
  }));

  // set identifier metadata
  const mfrpn = getOrCreateElement('meta', 'name', 'docsearch:manufacturerArticlenumber');
  const mfrpn_content = isModel ? productDetail.basicProductData.associations.article.products.join(',') : productDetail.basicProductData.identifier;
  mfrpn.setAttribute('content', mfrpn_content);

  if (!isModel) {
    // set ean metadata
    const europeanArticlenumber = getOrCreateElement('meta', 'name', 'docsearch:europeanArticlenumber');
    europeanArticlenumber.setAttribute('content', dataOrEmpty(productDetail.europeanArticlenumber) as string);
    // set thumbnail metadata
    const thumbnail = getOrCreateElement('meta', 'name', 'docsearch:thumbnail_url');
    thumbnail.setAttribute('content', dataOrEmpty(productDetail.thumbnail) as string);
  }

  // set designator metadata
  const designator = getOrCreateElement('meta', 'name', 'docsearch:designator');
  designator.setAttribute('content', product);

  // set description metadata
  const description = getOrCreateElement('meta', 'name', 'docsearch:description');
  description.setAttribute('content', dataOrEmpty(productDetail.basicProductData.description) as string);

  // set content metadata
  const content = getOrCreateElement('meta', 'name', 'docsearch:content');
  content.setAttribute('content', dataOrEmpty(productDetail.basicProductData.textShort) as string);

  // set family metadata
  const family_value = productDetail.basicProductData.family.toLowerCase();
  const family = getOrCreateElement('meta', 'name', 'docsearch:family');
  family.setAttribute('content', family_value);

  // calculate page_rank
  let page_rank_value: number;
  switch (family_value) {
    case 'kat': {
      page_rank_value = 30;
      break;
    }
    case 'ers': {
      page_rank_value = 20;
      break;
    }
    case 'alf': {
      page_rank_value = 10;
      break;
    }
    default: {
      page_rank_value = 0;
      break;
    }
  }
  page_rank_value = isModel ? (page_rank_value + 5) : page_rank_value;

  // set page_rank metadata
  const page_rank = getOrCreateElement('meta', 'name', 'docsearch:page_rank');
  page_rank.setAttribute('content', page_rank_value.toString());

  // set component info
  element.setAttribute('algolia-application-id', ALGOLIA_APPLICATION_ID);
  element.setAttribute('algolia-api-key', ALGOLIA_API_KEY);
  element.setAttribute('product', product);
  element.setAttribute('heading', productTitle);
  element.setAttribute('media-url', productDetail.basicProductData.imageUrl);
  element.setAttribute('media-alt-text', productTitle);
  element.setAttribute('rich-text', `[
    ${createElement('p', dataOrEmptySafe(productDetail.basicProductData.textLong))}
  ]`);

  element.setAttribute('technical', dataOrEmpty(productDetail.basicProductData.technicalData) as string);

  if (mappedArticles.length) {
    element.setAttribute('article', `[${mappedArticles.map((p) => createProductJSON(p, isModel)).join(',')}]`);
  }

  if (mappedAccessories.length) {
    element.setAttribute('accessories', `[${mappedAccessories.map((p) => createProductJSON(p)).join(',')}]`);
  }

  if (mappedSpareParts.length) {
    element.setAttribute('spare-parts', `[${mappedSpareParts.map((p) => createProductJSON(p)).join(',')}]`);
  }

  // component conditional info
  if (!isModel) {
    element.setAttribute('datasheet', dataOrEmpty(productDetail.productDatasheet) as string);
  }
  if (searchParams.locale === AkeneoLanguage.GERMAN_GERMANY) {
    element.setAttribute('show-price', '');
  }

  const breadcrumbElement = document.getElementsByTagName('x-breadcrumb')[0];
  if (breadcrumbElement) {
    let productBreadcrumbs;
    if (isModel) {
      productBreadcrumbs = await createProductModelBreadcrumbData(Promise.resolve(productDetail));
    } else {
      const productModelProvider = fetchProductModel(resolveProductDetailUrl(searchParams.locale, productDetail.parent, true), productsUrlProvider);
      productBreadcrumbs = await createProductDetailsBreadcrumbData(productDetail, productModelProvider);
    }

    // Override breadcrumbs
    breadcrumbElement.setAttribute(
      'breadcrumb',
      JSON.stringify(productBreadcrumbs),
    );
  }
};
