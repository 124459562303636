import { Page } from '../../interfaces/page';
import { generateComponentUrl } from '../helpers/tag-creation/generate-component-url';
import { generateWebComponentTags } from '../helpers/tag-creation/generate-web-component-tags';

export const renderBackToPage = (pageData: Page) => {
  const component = 'XBackToPage';
  const metaData = {
    componentIdentifier: 'backToPage',
    componentName: component,
    fileUrl: generateComponentUrl('components', 'x-back-to-page'),
    id: 'backToPage',
    ignoreInScaffolding: true,
  };
  const globalLabels = pageData.labelMaps.find((labelObj) => labelObj.contentType === 'page');
  const backLabel = globalLabels.labels.find((label) => label.fieldIdentifier === 'back-button-title').fieldValue;
  const str = generateWebComponentTags({
    data: {
      label: backLabel,
    },
    metaData,
  });

  return `<div class="medium-up">
      <div class="back-to-page-wrapper">
        ${str}
      </div>
    </div>`;
};
