import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { renameGeneralProps } from '../helpers/rename-general-props';
import { duplicateProps } from '../helpers/duplicate-props';

export const stageMapper: DataMapper = (normalizedData) => {
  const renamedProperties = renameGeneralProps(normalizedData.data, {
    'image-s.url': 'image-small.url',
    'image-s.altText': 'image-small.altText',
    'image-m.url': 'image-medium.url',
    'image-m.altText': 'images-medium.altText',
    'image-l.url': 'image-large.url',
    'image-l.altText': 'image-large.altText',
    'image.url': 'image-extra-large.url',
    'image.altText': 'image-extra-large.altText',
    'image-alignment': 'image.alignment',
    'image-alignment-large': 'image.alignmentLarge',
    headline: 'text.headline',
    claim: 'text.claim',
    'secondary-color-text': 'text.secondaryColor',
  });
  const enrichedProperties = duplicateProps(renamedProperties, {
    'image-small.alignment': 'image.alignment',
    'image-medium.alignment': 'image.alignment',
    'image-large.alignment': 'image.alignmentLarge',
    'image-extra-large.alignment': 'image.alignmentLarge',
    // Ensure backwards compatibility with older frontend-framework versions
    'image.url': 'image-extra-large.url',
    'image.altText': 'image-extra-large.altText',
  });

  return {
    data: {
      ...deleteProps(enrichedProperties, ['children']),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XStage',
      fileUrl: generateComponentUrl('components', 'x-stage'),
      fullWidth: true,
      noSpacingTop: true,
      noSpacingBottom: true,
    },
  };
};
