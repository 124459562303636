import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const teaserRowMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...mapChildren(normalizedData.data, 'items'),
      ...deleteProps(
        renameGeneralProps(normalizedData.data, {
          headline: 'heading',
        }),
        ['children'],
      ),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XTeaserRow',
      fileUrl: generateComponentUrl('components', 'x-teaser-row'),
      fullWidth: true,
    },
  };

  return mappedData;
};
