import { DataMapperForAttributes } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const brochureGroupMapper: DataMapperForAttributes = (normalizedData) => {
  const mappedData = {
      ...mapChildren(normalizedData.data, 'brochures'),
      ...deleteProps(
          renameGeneralProps(normalizedData.data, {
              headline: 'heading',
          }), ['children'],
      ),
  };

  mappedData.id = normalizedData.metaData.id;
  return mappedData;
};
