import { generateComponentUrl } from '../helpers/tag-creation/generate-component-url';
import { generateWebComponentTags } from '../helpers/tag-creation/generate-web-component-tags';

export const renderBackToTop = () => {
  const component = 'XBackToTop';
  const metaData = {
    componentIdentifier: 'backToTop',
    componentName: component,
    fileUrl: generateComponentUrl('components', 'x-back-to-top'),
    id: 88888,
    ignoreInScaffolding: true,
  };
  return generateWebComponentTags({
    data: {},
    metaData,
  });
};
