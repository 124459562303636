import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const offerActionMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...deleteProps(
        renameGeneralProps(normalizedData.data, {
          'link.link': 'link.url',
          'link.text': 'link.label',
        }),
        ['children'],
      ),
      ...mapChildren(normalizedData.data),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XOfferAction',
      fileUrl: generateComponentUrl('components', 'x-offer-action'),
    },
  };

  return mappedData;
};
