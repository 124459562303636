import { EZContentTypeObject } from '../interfaces/ez-content-type-object';

const contentTypeRepository: Map<string, EZContentTypeObject> = new Map<string, EZContentTypeObject>();

export const initialiseContentTypeRepository = async (): Promise<void> => {
  const contentTypeInfo = await fetch(`${EZAPI}/api/ibexa/v2/content/typegroups/4/types`, {
    headers: {
      Accept: 'application/vnd.ibexa.api.ContentTypeList+json',
    },
    method: 'GET',
  }).then((response) => response.json());

  contentTypeInfo.ContentTypeList.ContentType.forEach((ctInfo) => {
    contentTypeRepository.set(`${ctInfo.id}`, { ContentType: ctInfo });
  });
};

const fetchContentTypeObjectByContentTypeId = (contentTypeId: string): Promise<EZContentTypeObject> => {
  const url = `${EZAPI}/api/ibexa/v2/content/types/${contentTypeId}`;
  return fetch(url, {
    headers: {
      Accept: 'application/vnd.ibexa.api.ContentInfo+json',
    },
  })
    .then((res) => res.json())
    .then((json) => {
      contentTypeRepository.set(contentTypeId, json);
      return json;
    }).catch((error) => { throw new Error(`Could not fetch content type object for content type id ${contentTypeId}:\r\n\r\n${error}`); });
};

export const getContentTypeById = (contentTypeId: string): Promise<EZContentTypeObject> => {
  if (contentTypeRepository.has(contentTypeId)) {
    const contentTypeInfo = contentTypeRepository.get(contentTypeId);

    return new Promise((res) => res(contentTypeInfo));
  }
  return fetchContentTypeObjectByContentTypeId(contentTypeId);
};
