export const getContentId = async (locationId: number): Promise<number> => {
  const url = `${EZAPI}/api/ibexa/v2/content/locations?id=${locationId}`;
  return fetch(url, {
    headers: {
      Accept: 'application/vnd.ibexa.api.ContentInfo+json',
    },
  })
    .then((res) => res.json())
    .then((json) => {
      const id = json.Location.ContentInfo.Content._id;
      return id;
    });
};
