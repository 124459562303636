import { ProductsUrlProvider } from './products-url-provider';

export interface HalLink {
  href: string;
}

export interface ProductRelationLinks {
  self: HalLink;
}

export interface ProductRelation {
  _links: ProductRelationLinks;
  identifier: string;
  parentIdentifier: string;
  isModel: boolean;
}

export interface Category {
  _links: object;
  identifier: string;
  parentIdentifier: string;
  labels: object;
  label: string;
  channels: any;
  children: Category[];
  productRelations: ProductRelation[];
}

export interface PriceFormat {
  amount: string;
  currency: string;
}

export type AssociationFormat = { products: string[] };
export type DataFormat = { data?: string | PriceFormat[] }[];

export interface BasicProductData {
  identifier: string;
  imageUrl: string;
  designator: DataFormat;
  description: DataFormat;
  textShort: DataFormat;
  textLong: DataFormat;
  technicalData: DataFormat;
  family: string;
  associations: {
    accessory?: AssociationFormat;
    sparePart?: AssociationFormat;
    article?: AssociationFormat;
  };
}

export interface ProductModel {
  basicProductData: BasicProductData;
  url?: string;
}

export interface Product {
  basicProductData: BasicProductData;
  parent: string;
  designatorColor: DataFormat;
  colorMaterial: DataFormat;
  discountGroup: DataFormat;
  image: DataFormat;
  thumbnail: DataFormat;
  productDatasheet: DataFormat;
  europeanArticlenumber: DataFormat;
  price?: string;
  url?: string;
}

export interface BreadcrumbItem {
    heading: string;
    uri: string;
}

export const createCategoryImageUrl = (categoryOrProductModelIdentifier: string) => CATEGORY_IMAGE_BASE_URL.replace('{code}', categoryOrProductModelIdentifier);

export const dataOrEmpty = (array?: DataFormat) => (array && array[0] && array[0].data) || '';

export const fetchProductCategory = async (identifier: string, locale: string, withoutChildren = false): Promise<Category> => {
  const categoryEndpoint = `${PRODUCT_CATEGORIES_ENDPOINT}?withChildren=${!withoutChildren}`
    .replace('{locale}', locale)
    .replace('{id}', identifier);

  const {
    _links,
    parentIdentifier,
    labels,
    label,
    channels,
    children,
    productRelations,
  } = await fetch(categoryEndpoint).then((res) => res.json());

  return {
    _links,
    identifier,
    parentIdentifier,
    labels,
    label,
    channels,
    children,
    productRelations,
  };
};

export const fetchProductCategoryBreadcrumbItems = async (locale: string, identifier: string, productsUrlProvider: ProductsUrlProvider, removeRoot = false): Promise<BreadcrumbItem[]> => {
  const parents: BreadcrumbItem[] = [];
  let currentIdentifier = identifier;
  while (currentIdentifier) {
    const [
      url,
      { parentIdentifier, label },
    ] = await Promise.all([
      productsUrlProvider.fetchProductCategoryUrl(locale, currentIdentifier),
      fetchProductCategory(currentIdentifier, locale, true),
    ]);

    if (parentIdentifier || !removeRoot) {
      parents.unshift({ heading: label, uri: url });
    }
    currentIdentifier = parentIdentifier;
  }
  return parents;
};

export const createProductModelBreadcrumbData = async (productModelProvider: Promise<ProductModel>): Promise<BreadcrumbItem[]> => {
  const productModel = await productModelProvider;
  const breadcrumbs = [
    {
      heading: `${dataOrEmpty(productModel.basicProductData.designator)}`,
      uri: productModel.url,
    },
  ];

  return breadcrumbs;
};

export const createProductDetailsBreadcrumbData = async (productDetail: Product, productModelProvider: Promise<ProductModel>): Promise<BreadcrumbItem[]> => {
  const breadcrumbs = await createProductModelBreadcrumbData(productModelProvider);
  breadcrumbs.push({
    heading: `${dataOrEmpty(productDetail.designatorColor)}`,
    uri: productDetail.url,
  });

  return breadcrumbs;
};
