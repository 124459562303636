export const createHtmlElementFromString = (
  str = '<div></div>',
): HTMLElement => {
  const el: HTMLElement = document.createElement('div');
  el.innerHTML = str.replace(new RegExp('&#34;', 'g'), '\\"');
  const errorComponentElement = el.childNodes[0].nodeValue === 'error';
  if (errorComponentElement) {
    return el;
  }
  return (el.childNodes[0] as HTMLElement) || el;
};
