import { getScaffoldingClass } from '../get-scaffolding-class';
import { generateComponentNameFromString } from './generate-component-name-from-string';

export const generateWebComponentTags = (child: any) => {
  const { data, metaData } = child;
  const attributes = Object.keys(data)
    .map((key) => {
      if (key !== 'children') {
        if (typeof data[key] === 'string') {
          return `${key}="${data[key]}"`;
        }
        if (typeof data[key] === 'boolean' && data[key]) {
          return `${key}`;
        }
        if (typeof data[key] === 'object') {
          return `${key}='${JSON.stringify(data[key])}'`;
        }
      }
      return '';
    })
    .join(' ');

  let deps: any = [];
  if (metaData.dependencies) {
    deps = metaData.dependencies.map((dep) => `'${dep}'`).join(',');
  }

  const componentClass = getScaffoldingClass(metaData);

  /* eslint-disable @typescript-eslint/no-use-before-define */
  const renderDynamicComponent = (): string => `<${generateComponentNameFromString(metaData.componentName)}
      data-resources="[
        {
          paths: ['${metaData.fileUrl}'],
          dependsOn: [${deps}]
        }
      ]"
      class="${componentClass}"
      id="jumpmark-${metaData.id}"
      ${attributes}
      componentId="${metaData.id}"
    >
      ${renderChildren(data)}
    </${generateComponentNameFromString(metaData.componentName)}>
  `;
  /* eslint-enable @typescript-eslint/no-use-before-define */

  const createErrorComment = (): string => `<!--error-->${renderDynamicComponent()}<!--error-->`;

  return `${metaData.isErrorComponent ? createErrorComment() : renderDynamicComponent()}`;
};

const renderChildren = (data) => {
  if (data.children) {
    let childrenMarkup = '';

    data.children.forEach((child) => {
      if (child !== undefined) {
        childrenMarkup += generateWebComponentTags(child);
      }
    });
    return childrenMarkup;
  }
  return '';
};
