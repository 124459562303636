import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { deleteProps } from '../helpers/delete-props';
import { mapChildren } from '../helpers/map-children';

export const inPageNavigationMapper: DataMapper = (normalizedData) => {
  const mappedData = {
    data: {
      ...mapChildren(normalizedData.data, 'items'),
      ...deleteProps(normalizedData.data, ['children']),
      headline: normalizedData.data.headline || 'Content',
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XInPageNavigation',
      fileUrl: generateComponentUrl('components', 'x-in-page-navigation'),
    },
  };

  return mappedData;
};
