import { EZContentField } from '../interfaces/ez-content-field';
import { filterFieldsForLanguage } from './filter-fields-for-language';

export const getFieldsForLanguage = (
  data: any,
  lang: string,
): EZContentField[] => {
  const { field } = data.Content.CurrentVersion.Version.Fields;
  return filterFieldsForLanguage(field, lang);
};
