import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { normalizeLinkData } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const textMediaInlineMapper = (normalizedData) => {
  const mediaType = normalizedData.data['media-image'] ? 'image' : 'video';
  const mappedData = {
    data: {
      'media-type': mediaType,
      ...normalizeLinkData(normalizedData),
      ...renameGeneralProps(normalizedData.data, {
        title: 'heading',
        'text-content': 'rich-text',
        'media-image.url': 'media-url',
        'media-video': 'media-url',
        'media-image.altText': 'media-alt-text',
      }),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XTextMediaInline',
      fileUrl: generateComponentUrl('components', 'x-text-media-inline'),
    },
  };

  switch (mappedData.data['bullet-icon']) {
    case 'checkmark':
      mappedData.data['bullet-icon'] = 'check';
      break;
    case 'en-dash':
      mappedData.data['bullet-icon'] = 'dash';
      break;
    default:
      break;
  }

  if (mappedData.data['media-ratio'] !== '') {
    if (mappedData.data['media-ratio'] === 'square (1:1)') {
      mappedData.data['media-ratio'] = 'square';
    }

    if (mappedData.data['media-ratio'] === 'portrait (3:4)') {
      mappedData.data['media-ratio'] = 'portrait';
    }
  }

  return mappedData;
};
