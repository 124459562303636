import { CMSLanguageSettings } from '../../api-adapter/ez/configuration/cms-language-settings';
import { Page } from '../../interfaces/page';

export const appendHeader = (
    pageData: Page,
    targetElement: HTMLElement,
    lang: string,
) => {
    const header = document.createElement('x-navigation-header');
    header.dataset.resources = '[{paths: ["components/x-navigation-header/index.js"]}]';
    header.setAttribute(
        'nav-structure',
        JSON.stringify(pageData.navigationStructure.children),
    );
    header.setAttribute('home-url', pageData.navigationStructure.url);
    if (CMSLanguageSettings.languageHasLoginForEZLanguageCode(lang)) {
        header.setAttribute('login-available', 'true');
        header.setAttribute('login-url', CMSLanguageSettings.getLoginUrlForEZLanguageCode(lang));
    }
    // FIXME: remove search data dependencies from header
    header.setAttribute('algolia-application-id', ALGOLIA_APPLICATION_ID);
    header.setAttribute('algolia-api-key', ALGOLIA_API_KEY);
    // FIXME END
    header.setAttribute('lang', lang);

    const navLabels = pageData.labelMaps.find((labelObj) => labelObj.contentType === 'navigation');

    header.setAttribute('back-button-title', navLabels.labels.find((label) => label.fieldIdentifier === 'back-button-title').fieldValue);
    header.setAttribute('page-search-label', navLabels.labels.find((label) => label.fieldIdentifier === 'search-bar-placeholder').fieldValue);
    header.setAttribute('page-search-found-results-text', navLabels.labels.find((label) => label.fieldIdentifier === 'search-results-found-text').fieldValue);

    targetElement.appendChild(header);
};
