import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';

export const productDetailMapper: DataMapper = (normalizedData) => ({
    data: {
      ...normalizedData.data,
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XProductDetail',
      fileUrl: generateComponentUrl('components', 'x-product-detail'),
    },
});
