import { generateComponentUrl } from '../../render/helpers/tag-creation/generate-component-url';
import { DataMapper } from '../data-mapper';
import { mapChildren } from '../helpers/map-children';
import { normalizeLinkData } from '../../api-adapter/ez/page/normalization/normalize-link-data';
import { renameGeneralProps } from '../helpers/rename-general-props';

export const accordionMapper: DataMapper = (normalizedData) => {
  const { children } = normalizedData.data;
  if (children.length) {
    (children[0].data as any).opened = normalizedData.data['open-first-item'];
    children[children.length - 1].data['add-bottom-line'] = true;
  }
  const mappedData = {
    data: {
      ...renameGeneralProps(normalizedData.data, { title: 'heading' }),
      ...normalizeLinkData(normalizedData),
      ...mapChildren(normalizedData.data),
    },
    metaData: {
      ...normalizedData.metaData,
      componentName: 'XAccordion',
      fileUrl: generateComponentUrl('components', 'x-accordion'),
    },
  };

  return mappedData;
};
