import { EZContentField } from '../interfaces/ez-content-field';
import { getObjectForContentId } from './get-object-for-content-id';

export const getContentFieldsForContentId = async (
  contentId: number,
): Promise<EZContentField[]> => {
  const json = await getObjectForContentId(contentId);

  return json.Content.CurrentVersion.Version.Fields.field;
};
