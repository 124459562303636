import { Content } from '../../../interfaces/content';
import { xProductCategoryOverview } from './x-product-category-overview';
import { xProductDetail } from './x-product-detail';

const allComponentsReady = async (components: Array<any>) => Promise
  .all(components.map((element) => new Promise((res) => element.addEventListener('ready', res))));

export const postRendering = async (productCategoryOverviewContext: Content) => {
  const postRenderings = [];
  if (productCategoryOverviewContext !== undefined) {
    postRenderings.push(xProductCategoryOverview(productCategoryOverviewContext));
  }
  postRenderings.push(xProductDetail());

  await Promise.all(postRenderings);
  await allComponentsReady([].slice.call(document.querySelectorAll('[data-resources]')));
  window['rendered'] = true;
};
