import { filterFieldsForLanguage } from '../../api-adapter/ez/helpers/filter-fields-for-language';
import { getContentId } from '../../api-adapter/ez/helpers/get-content-id';
import { getLocationHierarchy } from '../../api-adapter/ez/helpers/get-location-hierarchy';
import { getLanguageUrl } from '../../api-adapter/ez/urls/url-repository';
import { generateComponentUrl } from '../helpers/tag-creation/generate-component-url';
import { generateWebComponentTags } from '../helpers/tag-creation/generate-web-component-tags';

const getContentFields = async (locationId: number) => {
  const contentId = await getContentId(locationId);
  const url = `${EZAPI}/api/ibexa/v2/content/objects/${contentId}/currentversion`;
  return fetch(url, {
    headers: {
      Accept: 'application/vnd.ibexa.api.Content+json',
    },
  })
    .then((res) => res.json())
    .then((json) => {
      try {
        return json.Version.Fields.field;
      } catch (error) {
        return error;
      }
    })
    .catch((err) => err);
};

const getBreadcrumbItem = async (pageId: number, lang: string) => {
  try {
    const [languageUrl, breadcrumbFields] = await Promise.all([
      getLanguageUrl(pageId, lang),
      getContentFields(pageId),
    ]);
    const breadcrumbData = filterFieldsForLanguage(breadcrumbFields, lang);

    const alt = () => {
      const a = breadcrumbData.find(
        (item) => item.fieldDefinitionIdentifier === 'title',
      );
      if (a === undefined) {
        return '';
      }
      return a.fieldValue;
    };

    const isBreadcrumbItem = breadcrumbData.filter(
      (item) => item.fieldDefinitionIdentifier === 'breadcrumb',
    ).length > 0;

    if (isBreadcrumbItem) {
      const title = () => {
        const breadcrumbField = breadcrumbData.find(
          (item) => item.fieldDefinitionIdentifier === 'breadcrumb',
        );
        if (breadcrumbField === undefined) {
          return '';
        }
        if (breadcrumbField.fieldValue === null) {
          return '';
        }
        return breadcrumbField.fieldValue;
      };
      return {
        id: pageId,
        uri: `${CMSROOT}${languageUrl}`,
        alt: alt(),
        heading: title(),
      };
    }

    return {
      id: undefined,
      uri: undefined,
      alt: undefined,
      heading: undefined,
    };
  } catch (error) {
    if (DEBUG) {
      // tslint:disable-next-line: no-console
      console.log(error);
    }
    return error;
  }
};

export const renderBreadcrumb = async (
  contentId: number,
  firstElementIsStage: boolean,
  lang: string,
) => {
  const locations = await getLocationHierarchy(contentId);
  try {
    const p = await Promise.all(
      locations.map((pageId) => getBreadcrumbItem(pageId, lang)),
    );
    const breadcrumb = p.filter((el: any) => el.id !== undefined && el.title !== '');
    const content = {
      id: 'breadcrumb',
      contentType: '',
      data: {
        breadcrumb,
      },
    };
    const component = 'XBreadcrumb';
    const metaData = {
      componentIdentifier: 'breadcrumb',
      componentName: component,
      fileUrl: generateComponentUrl('components', 'x-breadcrumb'),
      id: 999999,
      ignoreInScaffolding: true,
    };
    const str = generateWebComponentTags({
      data: { ...content.data },
      metaData,
    });

    return `<div class="medium-up">
        ${
      firstElementIsStage
        ? `
        <style>
          @media screen and (min-width: 768px) and (max-width: 991px) {
            .breadcrumb-wrapper {
              position: absolute;
              top: calc(75vw + 60px);
              width: 95%;
            }
          }
        </style>
        `
        : ''
      }
        <div class="breadcrumb-wrapper">
          ${str}
        </div></div>`;
  } catch (error) {
    if (DEBUG) {
      // tslint:disable-next-line: no-console
      console.log(error);
    }
  }
};
