import { CMSLanguageSettings } from './api-adapter/ez/configuration/cms-language-settings';
import { initialiseURLRepository } from './api-adapter/ez/urls/url-repository';
import { renderContentsToPage } from './render/render-contents-to-page';
import { setupResourceLoader } from './resource-loader';

const parseURL = (url: string) => {
  const parser = document.createElement('a');
  const searchObject = {};
  parser.href = url;
  const queries = parser.search.replace(/^\?/, '').split('&');
  for (const query of queries) {
    const split = query.split('=');
    [, searchObject[split[0]]] = split;
  }
  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject,
    hash: parser.hash,
  };
};

const init = async (data: any, options: any) => {
  const parsedUrl = parseURL(options.versionUrl);
  (window as any).time = new Date(options.timestamp * 1000);
  const pageId: number = options.versionUrl.split('/')[6];

  await initialiseURLRepository();

  let lang = (parsedUrl as any).searchObject.languages;
  lang = lang !== '' ? lang : CMSLanguageSettings.defaultLanguage;
  await renderContentsToPage(pageId, lang);

  setupResourceLoader();
  window.dispatchEvent(new CustomEvent('siedlePostRendering'));
};

// Entrypoint
(window as any).biotope = (window as any).biotope || {};
(window as any).biotope.render = init;
