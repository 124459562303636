import { LabelMap } from '../../../interfaces/label-map';
import { convertRichTextToJSON } from '../helpers/convert-rich-text-to-json';
import { filterFieldsForLanguage } from '../helpers/filter-fields-for-language';
import { getContentType } from '../helpers/get-content-type';
import { getLocationHierarchy } from '../helpers/get-location-hierarchy';
import { EZContentField } from '../interfaces/ez-content-field';
import { fetchViewRequest } from '../navigation/get-navigation-structure';

const labelContentTypeMap = {
    service_search_labels: [
      'service_search_dealer',
      'service_search_housing',
      'service_search_partner',
      'service_search_sales',
      'service_search_service',
    ],
    tab_container_labels: [
      'tab_container',
    ],
    navigation_labels: [
      'navigation',
    ],
    search_labels: [
      'navigation',
    ],
    form_contact_labels: [
      'form_contact',
    ],
    form_newsletter_unsubscribe_labels: [
      'form_newsletter_unsubscribe',
    ],
    form_newsletter_labels: [
      'form_newsletter',
    ],
    form_order_brochure_labels: [
      'form_order_brochure',
    ],
    page_back_label: [
      'page',
    ],
};

const resolveLabelContentFields = async (labelContentFields: EZContentField[], lang: string) => {
  const contentPromises = labelContentFields.map(async (contentField: EZContentField) => ({
    fieldIdentifier: contentField.fieldDefinitionIdentifier.replace(/_/g, '-'),
    fieldValue: contentField.fieldTypeIdentifier === 'ezrichtext' ? await convertRichTextToJSON(contentField.fieldValue, lang) : contentField.fieldValue,
  }));
  return Promise.all(contentPromises);
};

export const getGlobalLabels = async (globalLabelsFolderId: number, lang: string): Promise<LabelMap[]> => {
    const locations = await getLocationHierarchy(globalLabelsFolderId);
    const url = `${EZAPI}/api/ibexa/v2/views`;

    const tree = `/1/2/${locations.join('/')}/`;

    const queryBody = {
      ViewInput: {
        identifier: 'labels',
        public: false,
        ContentQuery: {
          FacetBuilders: {},
          Filter: {
            ContentTypeIdentifierCriterion: Object.keys(labelContentTypeMap),
            SubtreeCriterion: tree,
          },
          limit: 100000,
          offset: 0,
        },
      },
    };

    const contentData = await fetchViewRequest(
      url,
      queryBody,
    ).catch((error) => {
      throw new Error(
        `Label data could not be fetched from ${url}:\r\n\r\n${error}`,
      );
    });

    const labelResults = contentData.View.Result.searchHits.searchHit;
    const labelsContents: LabelMap[] = [];

    for (const labelData of labelResults) {
        const contentType = await getContentType(labelData.value);
        const contentFields = labelData.value.Content.CurrentVersion.Version.Fields.field;
        const filteredContentFields = filterFieldsForLanguage(contentFields, lang);

        const aimedContentTypes = labelContentTypeMap[contentType];
        if (aimedContentTypes) {
            aimedContentTypes.map(async (aimedContentType: string) => {
              const existingPropertyInMap = labelsContents.find((labelMap) => labelMap.contentType === aimedContentType);
              if (!existingPropertyInMap) {
                labelsContents.push({
                    contentType: aimedContentType,
                    labels: await resolveLabelContentFields(filteredContentFields, lang),
                });
              } else {
                existingPropertyInMap.labels.push(...await resolveLabelContentFields(filteredContentFields, lang));
              }
            });
        }
    }
    return labelsContents;
};
